import {
  ArrowDown24,
  ArrowUp24,
  Col,
  Input,
  Row,
  Select,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useGetPostalCodeDetailsLazyQuery } from '~/operations';
import { getRegionId } from '~/utils/getRegionId';
import styles from './RegisterAddress.module.scss';

export interface RegisterAddressProps {
  register: any;
  errors: any;
  setValue: any;
  addressState: { label: string; value: string };
}

['password', 'name', 'lastName', 'gender', 'dateOfBirth', 'cpf', 'phone'];

const hasError = (errors) => {
  const addressFields = [
    'addressAlias',
    'addressCEP',
    'addressStreet',
    'addressNumber',
    'addressNeighborhood',
    'addressCity',
    'addressState',
  ];
  return Object.keys(errors).some((k) => addressFields.includes(k));
};

export const RegisterAddress = ({ register, errors, setValue, addressState }: RegisterAddressProps) => {
  const [showForm, setShowForm] = useState(false);
  const [fetchPostalCodeDetails, { data }] = useGetPostalCodeDetailsLazyQuery();
  const getPostalCode = useCallback((v) => {
    const postalCode = v.target.value.replace(/[^\d]/g, '');
    if (postalCode.length !== 8) return;
    fetchPostalCodeDetails({ variables: { postalCode } });
  }, []);

  useEffect(() => {
    if (hasError(errors)) setShowForm(true);
  }, [errors]);

  useEffect(() => {
    if (data) {
      data?.getPostalCodeDetails?.streetType &&
        data?.getPostalCodeDetails?.street &&
        setValue('addressStreet', data?.getPostalCodeDetails?.streetType + ' ' + data?.getPostalCodeDetails?.street, {
          shouldValidate: true,
        });
      data?.getPostalCodeDetails?.neighborhood &&
        setValue('addressNeighborhood', data?.getPostalCodeDetails?.neighborhood, { shouldValidate: true });
      setValue('addressCity', data?.getPostalCodeDetails?.city || '', { shouldValidate: true });
      setValue(
        'addressState',
        {
          label: data?.getPostalCodeDetails?.stateCode || 'SP',
          value: getRegionId(data?.getPostalCodeDetails?.stateCode || 'SP'),
        },
        { shouldValidate: true }
      );
    }
  }, [data]);

  const rowClasses = clsx(!showForm && styles['content--hidden']);

  return (
    <>
      <Row>
        <Col lg={12}>
          <div
            onClick={() => setShowForm(!showForm)}
            className={clsx(
              styles['section-title'],
              showForm && styles['section-title--active'],
              !showForm && styles['section-title--inactive']
            )}
          >
            <Typography
              variant="h4"
              className={clsx(
                styles['section-title__text'],
                showForm && styles['section-title__text--active'],
                !showForm && styles['section-title__text--inactive']
              )}
            >
              Endereço
            </Typography>
            {showForm ? (
              <ArrowDown24 className={clsx(styles['section-title__icon'], styles['section-title__icon--active'])} />
            ) : (
              <ArrowUp24 className={clsx(styles['section-title__icon'], styles['section-title__icon--inactive'])} />
            )}
          </div>
        </Col>
      </Row>
      <Row className={clsx(rowClasses, styles['section-title__first-row'])}>
        <Col lg={8}>
          <Input
            label="Nome do endereço"
            name="addressAlias"
            placeholder="Ex.: Minha casa, trabalho, etc"
            ref={register}
            status={errors.addressAlias ? 'danger' : 'default'}
            helperText={errors.addressAlias?.message ?? ''}
            error={!!errors.addressAlias?.message}
            maxLength={255}
          />
        </Col>
        <Col lg={4}>
          <Input
            label="CEP"
            name="addressCEP"
            placeholder="00000-000"
            mask={TextfieldCommonMasks.cep}
            onBlur={getPostalCode}
            register={register}
            status={errors.addressCEP ? 'danger' : 'default'}
            helperText={errors.addressCEP?.message ?? ''}
            error={!!errors.addressCEP?.message}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={12}>
          <Input
            label="Endereço"
            name="addressStreet"
            placeholder="Rua ..."
            ref={register}
            maxLength={54}
            status={errors.addressStreet ? 'danger' : 'default'}
            helperText={errors.addressStreet?.message ?? ''}
            error={!!errors.addressStreet?.message}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={6}>
          <Input
            label="Número"
            name="addressNumber"
            placeholder="nº"
            maxLength={5}
            ref={register}
            status={errors.addressNumber ? 'danger' : 'default'}
            helperText={errors.addressNumber?.message ?? ''}
            error={!!errors.addressNumber?.message}
          />
        </Col>
        <Col lg={6}>
          <Input
            label="Complemento"
            name="addressComplement"
            placeholder="Aptº, bloco, quadra..."
            maxLength={14}
            ref={register}
            status={errors.addressComplement ? 'danger' : 'default'}
            helperText={errors.addressComplement?.message ?? ''}
            error={!!errors.addressComplement?.message}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={5}>
          <Input
            label="Bairro"
            name="addressNeighborhood"
            placeholder="Bairro"
            ref={register}
            maxLength={45}
            status={errors.addressNeighborhood ? 'danger' : 'default'}
            helperText={errors.addressNeighborhood?.message ?? ''}
            error={!!errors.addressNeighborhood?.message}
          />
        </Col>
        <Col lg={5}>
          <Input
            label="Cidade"
            name="addressCity"
            placeholder="Cidade"
            ref={register}
            maxLength={50}
            status={errors.addressCity ? 'danger' : 'default'}
            helperText={errors.addressCity?.message ?? ''}
            error={!!errors.addressCity?.message}
          />
        </Col>
        <Col lg={2}>
          <Select
            label="Estado"
            id="registration-state-select"
            placeholder="UF"
            value={addressState}
            onChange={(value) => setValue('addressState', value)}
            options={[
              { label: 'AC', value: '485' },
              { label: 'AL', value: '486' },
              { label: 'AP', value: '487' },
              { label: 'AM', value: '488' },
              { label: 'BA', value: '489' },
              { label: 'CE', value: '490' },
              { label: 'ES', value: '491' },
              { label: 'GO', value: '492' },
              { label: 'MA', value: '493' },
              { label: 'MT', value: '494' },
              { label: 'MS', value: '495' },
              { label: 'MG', value: '496' },
              { label: 'PA', value: '497' },
              { label: 'PB', value: '498' },
              { label: 'PR', value: '499' },
              { label: 'PE', value: '500' },
              { label: 'PI', value: '501' },
              { label: 'RJ', value: '502' },
              { label: 'RN', value: '503' },
              { label: 'RS', value: '504' },
              { label: 'RO', value: '505' },
              { label: 'RR', value: '506' },
              { label: 'SC', value: '507' },
              { label: 'SP', value: '508' },
              { label: 'SE', value: '509' },
              { label: 'TO', value: '510' },
              { label: 'DF', value: '511' },
            ]}
            status={errors.addressState ? 'danger' : 'default'}
            helperText={errors.addressState?.message ?? ''}
            error={!!errors.addressState?.message}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={12}>
          <Input
            label="Detalhes adicionais para entrega"
            name="deliveryDetails"
            placeholder="Edifício sem elevador, tem cachorro na residência, telefonar quando chegar etc."
            ref={register}
            status={errors.deliveryDetails ? 'danger' : 'default'}
            helperText={errors.deliveryDetails?.message ?? ''}
            error={!!errors.deliveryDetails?.message}
            maxLength={255}
          />
        </Col>
      </Row>
    </>
  );
};

export default RegisterAddress;
