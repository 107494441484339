import { ThreeDots24, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import useOnClickOutside from '~/utils/useOnClickOutside';
import styles from './CartContentOptionsModal.module.scss';

export interface CartContentOptionsModalProps {
  toggleDetails: () => void;
  onDelete: () => void;
}

export const CartContentOptionsModal = ({ onDelete, toggleDetails }: CartContentOptionsModalProps) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [optionsStatus, setOptionsStatus] = useState(false);
  useOnClickOutside<HTMLDivElement>(optionsRef, () => setOptionsStatus(false));

  return (
    <div className={styles['cart-product-options__more-options-icon']} ref={optionsRef}>
      <ThreeDots24 onClick={() => setOptionsStatus(!optionsStatus)} />
      <ul
        className={clsx(
          styles['cart-product-options__options-modal'],
          optionsStatus && styles['cart-product-options__options-modal--active']
        )}
      >
        <li
          onClick={() => {
            setOptionsStatus(false);
            toggleDetails();
          }}
        >
          <Typography variant="button" className={clsx(styles['cart-product-options__options-modal-item-text'])}>
            Ver detalhes
          </Typography>
        </li>
        <li
          onClick={() => {
            setOptionsStatus(false);
            onDelete();
          }}
        >
          <Typography
            variant="button"
            className={clsx(
              styles['cart-product-options__options-modal-item-text'],
              styles['cart-product-options__options-modal-item-text--delete']
            )}
          >
            Excluir
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default CartContentOptionsModal;
