import mailcheck from 'mailcheck';
interface CheckEmailResult {
  hasSuggestion: boolean;
  suggestion: string | undefined;
}
export const checkEmail = (email) => {
  return new Promise<CheckEmailResult>(function (resolve) {
    mailcheck.run({
      email,
      domains: defaultDomains,
      topLevelDomains: defaultTopLevelDomains,
      secondLevelDomains: defaultSecondLevelDomains,
      suggested: (suggestion) => {
        resolve({ hasSuggestion: true, suggestion: suggestion.full });
      },
      empty: () => {
        resolve({ hasSuggestion: false, suggestion: undefined });
      },
    });
  });
};
const defaultTopLevelDomains = [
  'br',
  'com',
  'com.br',
  'edu',
  'gov',
  'gov.br',
  'info',
  'jus',
  'jus.br',
  'mil',
  'mil.br',
  'net',
  'net.br',
  'org',
  'org.br',
];
const defaultSecondLevelDomains = [
  'bol',
  'globo',
  'gmail',
  'gmx',
  'gympass',
  'hotmail',
  'icloud',
  'ig',
  'live',
  'mail',
  'me',
  'msn',
  'objectedge',
  'outlook',
  'terra',
  'tunne',
  'uol',
  'yahoo',
  'ymail',
  'ziyou',
];
const defaultDomains = [
  '126.com',
  '163.com',
  '21cn.com',
  'aliyun.com',
  'aol.com',
  'att.net',
  'bell.net',
  'bellsouth.net',
  'bol.com.br',
  'bt.com',
  'btinternet.com',
  'charter.net',
  'comcast.net',
  'contus.in',
  'cox.net',
  'daum.net',
  'earthlink.net',
  'facebook.com',
  'foxmail.com',
  'games.com',
  'globo.com',
  'globomail.com',
  'gmail.com',
  'gmx.com',
  'gmx.net',
  'google.com',
  'googlemail.com',
  'gympass.com',
  'hanmail.net',
  'hotmail.com',
  'hotmail.com.br',
  'hush.com',
  'hushmail.com',
  'icloud.com',
  'ig.com.br',
  'iname.com',
  'inbox.com',
  'itelefonica.com.br',
  'juno.com',
  'laposte.net',
  'lavabit.com',
  'live.com',
  'love.com',
  'mac.com',
  'me.com',
  'msn.com',
  'nate.com',
  'naver.com',
  'ntlworld.com',
  'objectedge.com',
  'oi.com.br',
  'orange.net',
  'outlook.com',
  'outlook.com.br',
  'pobox.com',
  'protonmail.com',
  'qq.com',
  'r7.com',
  'rocketmail.com',
  'rogers.com',
  'safe-mail.net',
  'sbcglobal.net',
  'sina.com',
  'sky.com',
  'terra.com.br',
  'tunne.com.br',
  'tutamail.com',
  'tutanota.com',
  'uol.com.br',
  'verizon.net',
  'virgin.net',
  'virginmedia.com',
  'wow.com',
  'yahoo.com',
  'yahoo.com.br',
  'yandex.com',
  'yeah.net',
  'ygm.com',
  'ymail.com',
  'zipmail.com.br',
  'ziyou.com.br',
  'zoho.com',
];
