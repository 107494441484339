import { Radio, ThreeDots24, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import styles from './CardSelect.module.scss';

export interface CardSelectState {
  selectedAddress: string;
  selectedCard: string;
  disabled: boolean;
}
export interface CardSelectProps {
  selectedAddress: string;
  selectedCard: string;
  disabled: boolean;
}

export const CardSelect = (props: CardSelectProps) => {
  const [state, setState] = useState<CardSelectState>({ selectedAddress: '', selectedCard: '', disabled: false });

  /**
   * useEffect will run when selectedAddress or disabled value gets changed
   */
  useEffect(() => {
    if (props) {
      let { selectedAddress, disabled } = props;
      setState({ ...state, selectedAddress, disabled });
    }
  }, [props.selectedAddress, props.disabled]);

  const handleChange = (key: string, value: string) => {
    setState({ ...state, [key]: value });
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className={clsx(styles.creditcardContainer, 'col-sm-6 col-xs-12')}>
          <div className={clsx(styles.form)}>
            <Radio
              checked={state.selectedCard === 'first'}
              inline={true}
              type={'selectedCard'}
              value={'first'}
              labelClick={handleChange}
              label={
                <div className={clsx(styles.content)}>
                  <div className={clsx(styles.cardImg)}>
                    <img src="http://tineye.com/images/widgets/mona.jpg" alt="Img" />
                  </div>
                  <span>****</span>
                  <span>****</span>
                  <span>****</span>
                  <span>1234</span>
                </div>
              }
            />
            <ThreeDots24 />
          </div>
        </div>
        <div className={clsx(styles.creditcardContainer, 'col-sm-6 col-xs-12')}>
          <div className={clsx(styles.form)}>
            <Radio
              checked={state.selectedCard === 'second'}
              inline={true}
              type={'selectedCard'}
              value={'second'}
              labelClick={handleChange}
              label={
                <div className={clsx(styles.content)}>
                  <div className={clsx(styles.cardImg)}>
                    <img src="http://tineye.com/images/widgets/mona.jpg" alt="Img" />
                  </div>
                  <span>****</span>
                  <span>****</span>
                  <span>****</span>
                  <span>5678</span>
                </div>
              }
            />
            <ThreeDots24 />
          </div>
        </div>
        <div className={clsx(styles.creditcardContainer, 'col-sm-6 col-xs-12')}>
          <div className={clsx(styles.form)}>
            <Radio
              checked={state.selectedAddress === 'first'}
              inline={true}
              type={'selectedAddress'}
              value={'first'}
              labelClick={handleChange}
              label={
                <div className={clsx(styles.address)}>
                  <Typography variant="h5">Address 1</Typography>
                  <div className={clsx(styles.addressline)}>
                    <Typography variant="body-text-2">
                      Rua Engenheiro Domingos Ferreira, 1992 Apto 2020 complemento Boa Viagem - Cidade - UF 54440-440
                    </Typography>
                  </div>
                </div>
              }
            />
            <ThreeDots24 />
          </div>
        </div>
        <div className={clsx(styles.creditcardContainer, 'col-sm-6 col-xs-12')}>
          <div className={clsx(styles.form)}>
            <Radio
              checked={state.selectedAddress === 'second'}
              inline={true}
              type={'selectedAddress'}
              value={'second'}
              labelClick={handleChange}
              label={
                <div className={clsx(styles.address)}>
                  <Typography variant="h5">Address 2</Typography>
                  <div className={clsx(styles.addressline)}>
                    <Typography variant="body-text-2">
                      Rua Engenheiro Domingos Ferreira, 1892 Apto 2019 complemento Boa Viagem - Cidade - UF 64450-542
                    </Typography>
                  </div>
                </div>
              }
            />
            <ThreeDots24 />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CardSelect.defaultProps = {
  selectedAddress: '',
  selectedCard: '',
  disabled: '',
};

export default CardSelect;
