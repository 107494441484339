import { Radio, ThreeDots24, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import useOnClickOutside from '~/utils/useOnClickOutside';
import styles from '../CheckoutAddressSelection/CheckoutAddressSelection.module.scss';
import { CheckoutDeleteAddressModal } from '../CheckoutDeleteAddressModal';
import { CheckoutEditOrAddAddressModal } from '../CheckoutEditOrAddAddressModal';

export interface AddressCardProps {
  addressType: 'delivery' | 'billing';
  addressOption: any;
  selected: boolean;
  onSelect: Function;
  customerInfo: {
    firstName: string;
    lastName: string;
  };
  allowDelete?: boolean;
}

export const AddressCard = ({
  addressOption,
  addressType,
  selected,
  onSelect,
  customerInfo,
  allowDelete = true,
}: AddressCardProps) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const optionsRef = useRef<HTMLDivElement>(null);
  const [optionsStatus, setOptionsStatus] = useState(false);
  useOnClickOutside<HTMLDivElement>(optionsRef, () => setOptionsStatus(false));

  return (
    <>
      <li
        onClick={() => onSelect(addressOption)}
        className={clsx(
          styles['checkout-address-selection__address-option'],
          selected && styles['checkout-address-selection__address-option--active'],
          'cursor-pointer'
        )}
      >
        <Radio id="selectedAddress" name={`selectedAddress-${addressType}`} checked={selected} />
        <div>
          <Typography className={styles['checkout-address-selection__address-info-text']} variant="h5">
            {addressOption.company}
          </Typography>
          <Typography className={styles['checkout-address-selection__address-info-text']} variant="body-text-2">
            {addressOption.street[0]}, {addressOption.number}
          </Typography>
          <Typography className={styles['checkout-address-selection__address-info-text']} variant="body-text-2">
            {addressOption.complement}
          </Typography>
          <Typography className={styles['checkout-address-selection__address-info-text']} variant="body-text-2">
            {`${addressOption.neighborhood} - ${addressOption.city} - ${addressOption.region.region}`}
          </Typography>
          <Typography className={styles['checkout-address-selection__address-info-text']} variant="body-text-2">
            {addressOption.postcode}
          </Typography>
          {addressType == 'delivery' && addressOption.default_shipping && (
            <Typography
              className={clsx(styles['checkout-address-selection__address-info-text'], 'mt-3')}
              variant="body-text-2"
              weight="bold"
            >
              Endereço de entrega padrão
            </Typography>
          )}
          {addressType == 'billing' && addressOption.default_billing && (
            <Typography
              className={clsx(styles['checkout-address-selection__address-info-text'], 'mt-3')}
              variant="body-text-2"
              weight="bold"
            >
              Endereço de cobrança padrão
            </Typography>
          )}
        </div>
        <div className={styles['checkout-address-selection__more-options-icon']} ref={optionsRef}>
          <ThreeDots24
            onClick={(e) => {
              e.stopPropagation();
              setOptionsStatus(!optionsStatus);
            }}
          />
          <ul
            className={clsx(
              styles['checkout-address-selection__options-modal'],
              optionsStatus && styles['checkout-address-selection__options-modal--active']
            )}
          >
            <li
              onClick={(e) => {
                e.stopPropagation();
                setOptionsStatus(false);
                setEditModal(true);
              }}
            >
              <Typography
                variant="button"
                className={clsx(styles['checkout-address-selection__options-modal-item-text'])}
              >
                Editar
              </Typography>
            </li>
            {allowDelete && !addressOption.default_billing && !addressOption.default_shipping && (
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  setOptionsStatus(false);
                  setDeleteModal(true);
                }}
              >
                <Typography
                  variant="button"
                  className={clsx(
                    styles['checkout-address-selection__options-modal-item-text'],
                    styles['checkout-address-selection__options-modal-item-text--delete']
                  )}
                >
                  Excluir
                </Typography>
              </li>
            )}
          </ul>
        </div>
      </li>
      <CheckoutEditOrAddAddressModal
        id={addressOption.id}
        isOpen={editModal}
        closeModal={() => setEditModal(!editModal)}
        actionType="edit"
        addressType={addressType}
        title={`Editar endereço de ${addressType === 'delivery' ? 'entrega' : 'cobrança'}`}
        onSelect={onSelect}
        defaultValues={{
          name: addressOption.company,
          cep: addressOption.postcode,
          street: addressOption.street[0],
          number: addressOption.number,
          complement: addressOption.complement,
          neighborhood: addressOption.neighborhood,
          city: addressOption.city,
          state: { label: addressOption.region.region_code, value: addressOption.region.region_id.toString() },
          default_shipping: addressOption.default_shipping,
          default_billing: addressOption.default_billing,
          deliveryDetails: addressOption.deliveryDetails,
        }}
        customerInfo={customerInfo}
        addressEdited={addressOption}
      />
      <CheckoutDeleteAddressModal
        id={addressOption.id}
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(!deleteModal)}
        title={`Excluir endereço de ${addressType === 'delivery' ? 'entrega' : 'cobrança'}`}
      />
    </>
  );
};

export default AddressCard;
