export default [
  { label: 'Selecione: ', value: '0' },
  { label: 'AC', value: '485' },
  { label: 'AL', value: '486' },
  { label: 'AP', value: '487' },
  { label: 'AM', value: '488' },
  { label: 'BA', value: '489' },
  { label: 'CE', value: '490' },
  { label: 'ES', value: '491' },
  { label: 'GO', value: '492' },
  { label: 'MA', value: '493' },
  { label: 'MT', value: '494' },
  { label: 'MS', value: '495' },
  { label: 'MG', value: '496' },
  { label: 'PA', value: '497' },
  { label: 'PB', value: '498' },
  { label: 'PR', value: '499' },
  { label: 'PE', value: '500' },
  { label: 'PI', value: '501' },
  { label: 'RJ', value: '502' },
  { label: 'RN', value: '503' },
  { label: 'RS', value: '504' },
  { label: 'RO', value: '505' },
  { label: 'RR', value: '506' },
  { label: 'SC', value: '507' },
  { label: 'SP', value: '508' },
  { label: 'SE', value: '509' },
  { label: 'TO', value: '510' },
  { label: 'DF', value: '511' },
];
