import { Radio, ThreeDots24, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { getPublicPath } from '~/utils/getPublicPath';
import useOnClickOutside from '~/utils/useOnClickOutside';
import styles from '../CheckoutCardSelection/CheckoutCardSelection.module.scss';
import { CheckoutDeleteCardModal } from '../CheckoutDeleteCardModal';

export interface PaymentCardProps {
  cardOption: any;
  onSelect: Function;
  selected: boolean;
}

export const PaymentCard = ({ cardOption, onSelect, selected }: PaymentCardProps) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const optionsRef = useRef<HTMLDivElement>(null);
  const [optionsStatus, setOptionsStatus] = useState(false);
  useOnClickOutside<HTMLDivElement>(optionsRef, () => setOptionsStatus(false));

  const formatedNumber = cardOption.details.Number.match(/.{1,4}/g).join(' ');
  const cardImage = getPublicPath(`/cc-bandeiras/${cardOption.details.type}.png`);

  return (
    <>
      <li
        onClick={() => onSelect(cardOption)}
        className={clsx(styles.cardOption, selected && styles.cardOptionActive, 'cursor-pointer')}
      >
        <Radio id="selectedCard" name="selectedCard" checked={selected} />
        <div className={styles.cardInfo}>
          <div className={styles.cardLogo}>
            <img src={cardImage} alt="Credit Card" />
          </div>
          <Typography variant="h5">{formatedNumber}</Typography>
        </div>
        <div className={styles.moreOptions} ref={optionsRef}>
          <ThreeDots24
            onClick={(e) => {
              e.stopPropagation();
              setOptionsStatus(!optionsStatus);
            }}
          />
          <ul className={clsx(styles.optionsModal, optionsStatus && styles.optionsModalActive)}>
            <li
              onClick={(e) => {
                e.stopPropagation();
                setOptionsStatus(false);
                setDeleteModal(true);
              }}
            >
              <Typography
                variant="button"
                className={clsx(styles.optionsModalItemText, styles.optionsModalItemTextDelete)}
              >
                Excluir
              </Typography>
            </li>
          </ul>
        </div>
      </li>
      <CheckoutDeleteCardModal
        publicHash={cardOption.public_hash}
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(!deleteModal)}
        title="Excluir cartão"
      />
    </>
  );
};

export default PaymentCard;
