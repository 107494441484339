import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, Form, Input, Modal, Row, Select, TextfieldCommonMasks } from '@objectedge/ziyou-storefront-ds';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CountryCodeEnum,
  useCreateCustomerAddressMutation,
  useGetPostalCodeDetailsLazyQuery,
  useUpdateCustomerAddressMutation,
} from '~/operations';
import { createCustomerAddress as createCustomerAddressUpdater } from '~/state/cache/update';
import { getRegionId } from '~/utils/getRegionId';
import ufs from '~/utils/stateCodesMap';
import Yup from '~/utils/yup';

export interface AddressFormData {
  name: string;
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: { label: string; value: string };
  default_shipping: boolean;
  default_billing: boolean;
  deliveryDetails: string;
}

export interface CheckoutEditOrAddAddressModalProps {
  id?: number;
  isOpen: boolean;
  closeModal: () => void;
  defaultValues?: AddressFormData;
  actionType: 'create' | 'edit';
  addressType: 'delivery' | 'billing';
  title: string;
  customerInfo: {
    firstName: string;
    lastName: string;
  };
  addressEdited?: any;
  onSelect?: Function;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Campo é obrigatório'),
  cep: Yup.string().required('Campo é obrigatório').postalCode('Formato do cep é inválido'),
  street: Yup.string().required('Campo é obrigatório').max(54, 'Valor deve ter 54 caracteres ou menos'),
  number: Yup.string().required('Campo é obrigatório').max(5, 'Valor deve ter 5 digitos ou menos'),
  complement: Yup.string().max(14, 'Valor deve ter 14 caracteres ou menos'),
  neighborhood: Yup.string().required('Campo é obrigatório').max(45, 'Valor deve ter 45 caracteres ou menos'),
  city: Yup.string().required('Campo é obrigatório').max(50, 'Valor deve ter 50 caracteres ou menos'),
  state: Yup.object({
    label: Yup.string().required('Campo é obrigatório'),
    value: Yup.string().required('Campo é obrigatório'),
  }).required('Campo é obrigatório'),
});

export const CheckoutEditOrAddAddressModal = ({
  id,
  defaultValues,
  isOpen,
  closeModal,
  actionType,
  onSelect,
  title,
  customerInfo,
  addressEdited,
}: CheckoutEditOrAddAddressModalProps) => {
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [createCustomerAddress, { loading }] = useCreateCustomerAddressMutation({
    onCompleted: ({ createCustomerAddress: data }) => {
      onSelect && onSelect(data);
      closeModal();
    },
    update: createCustomerAddressUpdater,
  });
  const [updateCustomerAddress, { loading: loadingUpdate }] = useUpdateCustomerAddressMutation({
    onCompleted: closeModal,
  });
  const [getPostalCodeDetails] = useGetPostalCodeDetailsLazyQuery({
    onCompleted: ({ getPostalCodeDetails: data }) => {
      if (data === null) {
        setPostalCodeError(true);
        return;
      }
      if (data?.street) setValue('street', `${data?.streetType} ${data?.street}`);
      setValue('neighborhood', data?.neighborhood || '');
      setValue('city', data?.city || '');
      if (data?.stateCode) setValue('state', { label: data.stateCode, value: getRegionId(data.stateCode) });
    },
  });

  const { reset, handleSubmit, watch, register, errors, setValue } = useForm<AddressFormData>({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isOpen) reset(defaultValues);
  }, [isOpen]);

  useEffect(() => {
    register('state');
    register('name');
  }, [register]);

  const state = watch('state');
  const details = watch('deliveryDetails');

  const stateHelperMessage = useMemo(() => {
    if (errors.state?.label) return errors.state?.label?.message;
    if (errors.state?.value) return errors.state?.value?.message;
    return '';
  }, [errors]);

  const onSubmit = async (values: any) => {
    const commonVariables = {
      firstname: customerInfo.firstName,
      lastname: customerInfo.lastName,
      company: values.name,
      postcode: values.cep,
      street: [values.street],
      number: values.number,
      complement: values.complement,
      neighborhood: values.neighborhood,
      city: values.city,
      region_id: parseInt(values.state?.value),
      country_code: CountryCodeEnum.Br,
      default_shipping: values.default_shipping || false,
      default_billing: values.default_billing || false,
      deliveryDetails: values.deliveryDetails,
    };

    if (actionType === 'create') {
      // if (addressType === 'delivery') commonVariables.default_shipping = true;
      // if (addressType === 'billing') commonVariables.default_billing = true;
      createCustomerAddress({ variables: commonVariables });
      return;
    }

    if (!id || !addressEdited) return;

    updateCustomerAddress({ variables: { id, ...commonVariables } });
  };

  const fetchZipCode = useCallback(async (v) => {
    setPostalCodeError(false);
    const postalCode = (v.target.value || '').replace(/[^\d]/g, '');
    if (!postalCode || postalCode.length < 8) return;
    getPostalCodeDetails({ variables: { postalCode } });
  }, []);

  const counter = () => `${(details || '').length} de 300 caracteres`;

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      cancelButtonLabel="Cancelar"
      okButtonLabel={actionType === 'create' ? 'Adicionar' : 'Salvar'}
      okDisabled={loading || loadingUpdate}
      showCancelButton
      size="lg"
    >
      <Form>
        <Container>
          <Row className="no-x-margin-md">
            <Col lg={8} className="no-x-padding-md">
              <Input
                label="Nome do lugar"
                name="name"
                placeholder="Ex: Minha casa, trabalho, etc"
                ref={register}
                status={errors.name ? 'danger' : 'default'}
                helperText={errors.name?.message ?? ''}
              />
            </Col>
            <Col lg={4} className="no-x-padding-md">
              <Input
                label="CEP"
                name="cep"
                onBlur={fetchZipCode}
                mask={TextfieldCommonMasks.cep}
                placeholder="00000-000"
                ref={register}
                register={register}
                status={errors.cep || postalCodeError ? 'danger' : 'default'}
                helperText={postalCodeError ? 'CEP inválido' : errors.cep?.message ?? ''}
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={12} className="no-x-padding-md">
              <Input
                label="Endereço"
                name="street"
                placeholder="Rua ..."
                maxLength={54}
                ref={register}
                status={errors.street ? 'danger' : 'default'}
                helperText={errors.street?.message ?? ''}
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={6} className="no-x-padding-md">
              <Input
                label="Número"
                name="number"
                placeholder="nº"
                maxLength={5}
                ref={register}
                status={errors.number ? 'danger' : 'default'}
                helperText={errors.number?.message ?? ''}
              />
            </Col>
            <Col lg={6} className="no-x-padding-md">
              <Input
                label="Complemento"
                name="complement"
                placeholder="Aptº, bloco, quadra..."
                maxLength={14}
                ref={register}
                status={errors.complement ? 'danger' : 'default'}
                helperText={errors.complement?.message ?? ''}
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={5} className="no-x-padding-md">
              <Input
                label="Bairro"
                name="neighborhood"
                placeholder="Bairro"
                maxLength={45}
                ref={register}
                status={errors.neighborhood ? 'danger' : 'default'}
                helperText={errors.neighborhood?.message ?? ''}
              />
            </Col>
            <Col lg={5} className="no-x-padding-md">
              <Input
                label="Cidade"
                name="city"
                placeholder="Cidade"
                ref={register}
                maxLength={50}
                status={errors.city ? 'danger' : 'default'}
                helperText={errors.city?.message ?? ''}
              />
            </Col>
            <Col lg={2} className="no-x-padding-md">
              <Select
                label="Estado"
                placeholder="UF"
                value={state}
                // TODO: Check better way to set types here
                onChange={(value) => setValue('state', value as { label: string | undefined; value: string })}
                options={ufs}
                status={errors.state ? 'danger' : 'default'}
                helperText={stateHelperMessage}
                autocomplete="false"
              />
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={12} className="no-x-padding-md">
              <Input
                label="Detalhes adicionais para entrega"
                name="deliveryDetails"
                placeholder="Edifício sem elevador, tem cachorro na residência, telefonar quando chegar, etc."
                ref={register}
                maxLength={300}
                helperText={counter()}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  );
};

export default CheckoutEditOrAddAddressModal;
