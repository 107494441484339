import { Slider as Navigation } from '@objectedge/ziyou-storefront-ds';
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import { ProductDescriptionCard } from '~/components/checkout';
import { parseMinimumRequirements } from '~/utils/product';
import styles from './ProductsDescription.module.scss';

export interface ProductsDescriptionProps {
  items: any;
}

export const ProductsDescription = ({ items }: ProductsDescriptionProps) => {
  const sliderRef: any = useRef(null);
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => setCurrentSliderIndex(next),
  };

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const index = parseInt(e.currentTarget.value);
    setCurrentSliderIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div className={styles['products-description']}>
      <Slider ref={sliderRef} {...settings} className={styles['slick']}>
        {items.map((item, index) => (
          <ProductDescriptionCard
            image={item.product.description_image.url}
            index={index}
            info={parseMinimumRequirements(item.product.minimum_requirements)}
            name={item.product.name}
            key={item.id}
          />
        ))}
      </Slider>
      {items.length > 1 && (
        <div className={styles['slider-desktop']}>
          <Navigation items={items.length} onChange={onChange} index={currentSliderIndex} />
        </div>
      )}
    </div>
  );
};

export default ProductsDescription;
