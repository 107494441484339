import { useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { ErrorText } from '~/components/forms';
import { validation } from '~/utils/customer';

export interface PasswordValidationProps {
  password?: string;
  className?: string;
}

const schema = validation.getSchema({ validPassword: 'password' });

export const PasswordValidation = ({ password = '', className }: PasswordValidationProps) => {
  const [errors, setErrors] = useState<ValidationError>();
  const has = (type: string) => password !== '' && errors && errors.inner.some((e) => e.type === type);

  useEffect(() => {
    schema
      .validate({ password }, { abortEarly: false })
      .then(() => setErrors(undefined))
      .catch(setErrors);
  }, [password]);

  return (
    <div className={className}>
      <ErrorText hasNotValidated={!password} hasTheError={has('hasMoreOrEqualThanSixCaracters')}>
        No mínimo 6 caracteres
      </ErrorText>
      {/* <ErrorText hasNotValidated={!password} hasTheError={has('hasMoreOrEqualThanEightCaracters')}>
        No mínimo 8 caracteres
      </ErrorText>
      <ErrorText hasNotValidated={!password} hasTheError={has('hasLowerCase')}>
        Ao menos uma letra minúscula
      </ErrorText>
      <ErrorText hasNotValidated={!password} hasTheError={has('hasUpperCase')}>
        Ao menos uma letra maiúscula
      </ErrorText>
      <ErrorText hasNotValidated={!password} hasTheError={has('hasNumber')}>
        Um número
      </ErrorText>
      <ErrorText hasNotValidated={!password} hasTheError={has('hasSpecialCaracters')}>
        Um caracter especial (@, _, #, etc)
      </ErrorText> */}
    </div>
  );
};

export default PasswordValidation;
