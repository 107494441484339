import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonSize, ButtonVariant, Form, Input, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIsEmailAvailableLazyQuery } from '~/operations';
import Yup from '~/utils/yup';
import { EmailSuggestion } from '../EmailSuggestion';
import styles from './RegisterForm.module.scss';

type RegisterFormData = {
  email: string;
};

const schema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Campo é obrigatório')
    .matches(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/, 'Email inválido'),
});

export const RegisterForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState('');
  const Router = useRouter();

  const { register, handleSubmit, errors, setError, control, setValue } = useForm<RegisterFormData>({
    resolver: yupResolver(schema),
  });

  const [isEmailAvailable, { loading }] = useIsEmailAvailableLazyQuery({
    onCompleted: ({ isEmailAvailable }) => {
      if (isEmailAvailable?.is_email_available) {
        typeof window !== 'undefined' ? localStorage.setItem('email', email) : null;
        Router.push({ pathname: '/cadastro', query: Router.query });
      } else {
        setError('email', {
          message: 'Já existe outro usuário com este email.',
        });
      }
    },
  });

  const onSubmit = useCallback((formValues: RegisterFormData) => {
    setEmail(formValues?.email);
    isEmailAvailable({ variables: { email: formValues?.email } });
  }, []);

  const emailWatcher = useWatch({
    control,
    name: 'email',
    defaultValue: '',
  });

  return (
    <section className={styles['register-form']}>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles['register-form__content']}>
        <Typography variant="h1" className={styles['register-form__title']}>
          Torne-se um assinante
        </Typography>
        {/* <Typography variant="body-text-1" className={styles['register-form__description']}>
          Seja ZiYou e participe do clube especializado em produtos de bem estar por assinatura sem precisar sair de
          casa.
        </Typography> */}
        <Typography variant="body-text-1" weight="bold" className={styles['register-form__description']}>
          *disponível para a cidade de São Paulo
        </Typography>
        <Typography variant="body-text-1" className={styles['register-form__or-text']}>
          ou
        </Typography>
        <Input
          label="Informe seu Email"
          name="email"
          ref={register}
          id="registration-email-input"
          className={clsx(
            styles['register-form__email-field'],
            !showForm && styles['register-form__email-field--hidden']
          )}
          status={errors.email ? 'danger' : 'default'}
          helperText={errors.email?.message ?? ''}
          error={!!errors.email?.message}
        />
        <EmailSuggestion value={emailWatcher} setFormValue={setValue} fieldName={'email'} />
        <Button
          size={ButtonSize.LG}
          id="registration-button"
          className={clsx(styles['register-form__button'], !showForm && styles['register-form__button--hidden'])}
        >
          Continuar
        </Button>
        <Button
          variant={ButtonVariant.DARK}
          outline
          disabled={loading}
          onClick={() => setShowForm(true)}
          id="registration-create-account-button"
          className={clsx(
            styles['register-form__create-account-button'],
            showForm && styles['register-form__create-account-button--hidden']
          )}
        >
          Criar Conta
        </Button>
        {/* <TestInfo /> */}
      </Form>
    </section>
  );
};

export default RegisterForm;
