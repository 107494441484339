import { Modifier, Modifiers } from '@apollo/client/cache/core/types/common';
import { ApolloCache, MutationUpdaterFn } from '@apollo/client/core';
import {
  AddCreditCardMutation,
  CreateCustomerAddressMutation,
  DeleteCustomerAddressMutation,
  DeletePaymentTokenMutation,
  DeleteCreditCardTokenMutation,
  CreateCreditCardTokenMutation,
} from '~/operations';
import CustomerAddressFragment from '~/operations/fragments/CustomerAddressFragment.graphql';
import CreditCardTokenFragment from '~/operations/fragments/CreditCardTokenFragment.graphql';
import PaymentTokenFragment from '~/operations/fragments/PaymentTokenFragment.graphql';

// Apply a modification into the logged in customer reference in cache
function modifyCustomer<T>(cache: ApolloCache<T>, fields: Modifiers | Modifier<any>) {
  cache.modify({
    fields: {
      customer: (ref) => {
        cache.modify({ id: cache.identify(ref), fields });
      },
    },
  });
}

export const createCustomerAddress: MutationUpdaterFn<CreateCustomerAddressMutation> = (cache, { data }) => {
  modifyCustomer<CreateCustomerAddressMutation>(cache, {
    addresses: (existing = []) => {
      const ref = cache.writeFragment({
        data: data?.createCustomerAddress,
        fragment: CustomerAddressFragment,
      });

      // // In case we need to update the defaults in the cache
      // existing.forEach((ref) => {
      //   cache.modify({
      //     id: cache.identify(ref),
      //     fields: {
      //       default_billing: (e, a) => {
      //         return e;
      //       },
      //     },
      //   });
      // });
      return [...existing, ref];
    },
  });
};

export const addCreditCard =
  (variables): MutationUpdaterFn<AddCreditCardMutation> =>
  (cache, { data }) => {
    cache.modify({
      fields: {
        customerPaymentTokens: ({ items = [], ...existing } = {}) => {
          const newTokenId = data?.addCreditCard?.id;
          if (newTokenId) {
            const ref = cache.writeFragment({
              data: {
                __typename: 'PaymentToken',
                details: JSON.stringify({
                  Number: '************' + variables.number.substr(variables.number.length - 4),
                  ExpirationDate: variables.expirationDate,
                  type: variables.type,
                  Holder: variables.holder,
                }),
                isDefault: true,
                payment_method_code: 'ziyou_braspag',
                public_hash: newTokenId,
                type: 'card',
              },
              fragment: PaymentTokenFragment,
            });

            items = [...items, ref];
          }

          existing.items = items;
          return existing;
        },
      },
    });
  };

export const createCreditCard =
  (variables): MutationUpdaterFn<CreateCreditCardTokenMutation> =>
  (cache, { data }) => {
    cache.modify({
      fields: {
        creditCardTokens: (tokens = []) => {
          const newTokenId = data?.createCreditCardToken?.edge?.id;
          if (newTokenId) {
            const ref = cache.writeFragment({
              data: {
                __typename: 'CreditCardVault',
                id: data?.createCreditCardToken?.edge?.id,
                createdAt: data?.createCreditCardToken?.edge?.createdAt,
                expirationDate: data?.createCreditCardToken?.edge?.expirationDate,
                holder: data?.createCreditCardToken?.edge?.holder,
                isDefault: data?.createCreditCardToken?.edge?.isDefault,
                issuer: data?.createCreditCardToken?.edge?.issuer,
                lastFour: data?.createCreditCardToken?.edge?.lastFour,
                ownerId: data?.createCreditCardToken?.edge?.ownerId,
                status: data?.createCreditCardToken?.edge?.status,
              },
              fragment: CreditCardTokenFragment,
            });

            tokens = [...tokens, ref];
          }
          return tokens;
        },
      },
    });
  };

export const deleteCustomerAddress =
  (id: number): MutationUpdaterFn<DeleteCustomerAddressMutation> =>
  (cache) => {
    cache.evict({ id: cache.identify({ __typename: 'CustomerAddress', id }) });
  };

export const deletePaymentToken =
  (hash: string): MutationUpdaterFn<DeletePaymentTokenMutation> =>
  (cache) => {
    cache.evict({ id: cache.identify({ __typename: 'PaymentToken', public_hash: hash }) });
  };

export const deleteCreditCardToken =
  (id: string): MutationUpdaterFn<DeleteCreditCardTokenMutation> =>
  (cache) => {
    cache.evict({ id: cache.identify({ __typename: 'CreditCardVault', id }) });
  };
