import { Button, ButtonVariant, Loader, Typography } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import OrderTotals from '~/components/checkout/OrderTotals';
import { CountryCodeEnum, useGetCartQuery, useSetShippingAddressMutation } from '~/operations';
import { useAlertsContext } from '~/utils/useAlerts';
import { useCartContext } from '~/utils/useCart';
import { useCookiesContext } from '~/utils/useCookies';
import styles from './CartSidebar.module.scss';

const DEFAULT_SHIPPING_ADDRESS = {
  city: 'São Paulo',
  country_code: CountryCodeEnum.Br,
  firstname: 'John',
  lastname: 'Doe',
  street: ['Av Brasil'],
  telephone: '9999999999',
  postcode: '01001001',
  region: 'SP',
};

export const CartSidebar = () => {
  const { state: cookieData } = useCookiesContext();
  const { state: cartData, actions: cartActions } = useCartContext();
  const { actions: alertActions } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const Router = useRouter();
  const hasNonVirtualProduct = cartActions.hasNonVirtualProduct();
  // Fetch data from apollo cache
  const { data } = useGetCartQuery({ variables: { cartId: cartData.id } });

  const [setDefaultAddress] = useSetShippingAddressMutation({
    variables: {
      cartId: cartData.id,
      shippingAddress: DEFAULT_SHIPPING_ADDRESS,
    },
  });

  useEffect(() => {
    // Always set a default address in the cart, in order to be able to calculate
    // the shipping price here. With a default address, we will be able to get the
    // flatrate and eventually be able to check if a free shipping is available

    if (data && !data.cart?.shipping_addresses.length && hasNonVirtualProduct) {
      setDefaultAddress();
    }
  }, [data]);

  const urlToRedirect = useMemo(() => {
    if (cookieData?.token) {
      setLoading(false);
      return '/checkout';
    }
    setLoading(false);
    return '/login?from=checkout';
  }, [cookieData]);

  const proceedToCheckout = () => {
    if (cartData.products.some((product) => product.stockQty < product.cartQty)) {
      alertActions.addDangerAlert(
        'Um ou mais produtos do seu carrinho estão indisponíveis. Remova-os para prosseguir.',
        {
          id: 'oos-error',
        }
      );
    } else {
      setLoading(true);
      Router.push(urlToRedirect);
    }
  };

  return (
    <aside className={styles['cart-sidebar']}>
      {loading ? <Loader message="Carregando carrinho..." full={true} /> : null}
      <Typography variant="h4" className={styles['cart-sidebar__title']}>
        Resumo da Assinatura
      </Typography>
      <OrderTotals cart={data?.cart as any} className="mb-3" />
      {/* <CouponInput cart={data?.cart as any} /> */}
      <div className={styles['cart-sidebar__buttons']}>
        <Button id="cart-checkout-button" className={styles['cart-sidebar__send-button']} onClick={proceedToCheckout}>
          Continuar
        </Button>
        <Link href="/todas-modalidades">
          <a id="cart-keep-shopping-button">
            <Button variant={ButtonVariant.DARK} outline className={styles['cart-sidebar__add-more-button']}>
              Adicionar mais equipamentos
            </Button>
          </a>
        </Link>
      </div>
    </aside>
  );
};

export default CartSidebar;
