import {
  Confirm24,
  ConfirmWhite24,
  Id24,
  IdWhite24,
  Map24,
  MapWhite24,
  Pagamento24,
  PagamentoWhite24,
  Stepper,
} from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useState } from 'react';
import { useCartContext } from '~/utils/useCart';
import styles from './CheckoutStepper.module.scss';

export interface CheckoutStepperProps {
  initialStep: number;
  hasCompactMenu?: boolean;
}

export const CheckoutStepper = ({ initialStep, hasCompactMenu = true }: CheckoutStepperProps) => {
  const [step, setStep] = useState(() => initialStep);
  const { actions: cartActions } = useCartContext();
  const hasNonVirtualProduct = cartActions.hasNonVirtualProduct();
  return (
    <Stepper
      value={step}
      // onClick={(value) => setStep(value)}
      steps={[
        {
          id: 1,
          activeIcon: <IdWhite24 />,
          inactiveIcon: <Id24 />,
          text: 'Identificação',
        },
        {
          id: 2,
          activeIcon: hasNonVirtualProduct ? <MapWhite24 /> : <PagamentoWhite24 />,
          inactiveIcon: hasNonVirtualProduct ? <Map24 /> : <Pagamento24 />,
          text: hasNonVirtualProduct ? 'Entrega e pagamento' : 'Pagamento',
          responsiveText: hasNonVirtualProduct ? 'Entrega' : 'Pagamento',
        },
        {
          id: 3,
          activeIcon: <ConfirmWhite24 />,
          inactiveIcon: <Confirm24 />,
          text: 'Confirmação assinatura',
          responsiveText: 'Confirmação',
        },
      ]}
      className={clsx(styles['checkout-stepper'], !hasCompactMenu && styles['checkout-stepper--without-compact-menu'])}
    />
  );
};

export default CheckoutStepper;
