import { Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { getMediaPath } from '~/utils/getPublicPath';
import styles from './ClassDescription.module.scss';

export const ClassDescription = () => (
  <div className={styles['class-description']}>
    <Typography variant="h5">Membro ZiYou</Typography>
    <div className={clsx(styles['class-description-card'], 'py-3')}>
      <div className={styles.imageContainer}>
        <img
          src={getMediaPath('/classes/class-description.webp')}
          alt={'name'}
          className={styles['class-description-card__image']}
        />
      </div>
    </div>
    <div>
      <Typography variant="h5"> Sua assinatura lhe concede acesso imediato a todas as aulas.</Typography>
      <Typography variant="h5" weight="normal">
        Aproveite agora mesmo! Acesse aqui e tenha uma experiência transformadora para sua saúde!
      </Typography>
    </div>
  </div>
);

export default ClassDescription;
