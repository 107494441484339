import { Typography } from '@objectedge/ziyou-storefront-ds';
import { Cart } from '~/operations';
import { isVirtualCart } from '~/utils/cart';
import { getDiscounts, getFreightPrice, getMonthlyPrice, getSubtotal } from '~/utils/checkout';
import { formatCurrency } from '~/utils/format/currency';
import styles from './OrderTotals.module.scss';

export interface OrderTotalsProps {
  cart?: Partial<Cart>;
  className?: string;
}

export const OrderTotals = ({ cart, className }: OrderTotalsProps) => {
  const products = cart?.items || [];
  const getTotalItemsCount = products?.reduce((total: number, product: any) => {
    return total + product?.quantity;
  }, 0);
  const freight = getFreightPrice(cart);
  const grandTotal = getMonthlyPrice(cart);
  const subtotal = getSubtotal(cart);
  const discounts = getDiscounts(cart);
  const formatedFreight = freight > 0 ? formatCurrency(freight) : 'Frete Grátis!';

  const hasDelivery = !isVirtualCart(cart);

  return (
    <div className={className}>
      <div className={styles.totals}>
        {hasDelivery && (
          <>
            <div>
              <Typography variant="h6" className="color-dark">{`Subtotal (${getTotalItemsCount})`}</Typography>
              <Typography variant="h6" className="color-chumbo">{`${formatCurrency(subtotal)}/m`}</Typography>
            </div>
            <div>
              <Typography variant="h6" className="color-dark">
                Descontos
              </Typography>
              <Typography variant="h6" className={discounts.order > 0 ? 'color-success' : 'color-grey'}>
                {formatCurrency(discounts.order)}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" className="color-dark">
                Entrega e Instalação
              </Typography>
              <Typography variant="h6" className={freight === 0 ? 'color-success' : 'color-chumbo'}>
                {formatedFreight}
              </Typography>
            </div>
          </>
        )}
        <div>
          <Typography variant="h6" className="color-dark">
            Total Mensal
          </Typography>
          <Typography variant="h4">{`${formatCurrency(grandTotal)}/m`}</Typography>
        </div>
      </div>
      {/*discounts.items > 0 && (
        <div className={styles.discount}>
          <CheckCircle24 />
          <Typography variant="caption-2" className="m-0">
            Você {confirmation ? 'economizou' : 'está economizando'} {`${formatCurrency(discounts.items)}/m`}{' '}
            <span>nesta assinatura</span>
          </Typography>
        </div>
      )*/}
    </div>
  );
};

export default OrderTotals;
