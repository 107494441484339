
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PaymentTokenFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PaymentToken"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"public_hash"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"details"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isDefault"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"payment_method_code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":115}};
    doc.loc.source = {"body":"fragment PaymentTokenFragment on PaymentToken {\n  public_hash\n  details\n  isDefault\n  payment_method_code\n  type\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
