import { Input, Button, ButtonVariant, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useState } from 'react';
import { Cart, useApplyCouponMutation, useRemoveCouponMutation } from '~/operations';
import styles from './CouponInput.module.scss';

export interface CouponInputProps {
  cart?: Partial<Cart> | null;
  className?: string;
}

export const CouponInput = ({ cart, className }: CouponInputProps) => {
  const [applyCoupon, { loading: applying }] = useApplyCouponMutation();
  const [removeCoupon, { loading: removing }] = useRemoveCouponMutation();
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { id: cartId = '', applied_coupons: coupons } = cart || {};

  const handleApply = async () => {
    if (!code) return;
    try {
      await applyCoupon({ variables: { cartId, code } });
      setCode('');
      setError('');
    } catch (e) {
      if (e.message.includes('already applied')) {
        setError('Este cupom já está aplicado neste pedido');
      } else if (e.message.includes("isn't valid")) {
        setError('Este cupom não existe ou já foi utilizado');
      } else {
        setError('Um erro ocorreu');
      }
    }
  };

  const handleRemove = async () => {
    removeCoupon({ variables: { cartId } });
  };

  return (
    <div className={clsx(styles.couponInput, className)}>
      <Typography variant="h5" className="mb-1">
        Possui cupom de desconto ou vale presente?
      </Typography>
      {coupons && coupons.length > 0 ? (
        coupons.map((c) => (
          <div key={c?.code} className={styles.coupon}>
            <div className={clsx(removing && styles.removing)}>
              <Typography variant="body-text-1" className="mb-1">
                Cupom
              </Typography>
              <Typography variant="body-text-1" weight="bold">
                {c?.code}
              </Typography>
              {/* <div className="d-flex justify-content-between">
                <Typography variant="body-text-1">Valor do desconto</Typography>
                <Typography variant="body-text-1">R$ 150,00</Typography>
              </div> */}
              <Button variant={ButtonVariant.LINK} onClick={handleRemove}>
                Remover
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div className={styles.form}>
          <Input
            value={code}
            label="Digite o cupom"
            status={!!error ? 'danger' : 'default'}
            helperText={error}
            onChange={(e) => {
              setCode(e.target.value);
              setError('');
            }}
            className={styles.input}
          />
          <Button block variant={ButtonVariant.LINK} onClick={handleApply} disabled={applying}>
            Aplicar
          </Button>
        </div>
      )}
    </div>
  );
};

export default CouponInput;
