import { Modal } from '@objectedge/ziyou-storefront-ds';
import { useDeleteCustomerAddressMutation, DeleteCustomerAddressMutation } from '~/operations';
import { deleteCustomerAddress as updateDeleteCustomerAddress } from '~/state/cache/update';

export interface CheckoutDeleteAddressModalProps {
  id: number;
  isOpen: boolean;
  closeModal: () => void;
  title: string;
}

const optimisticResponse: DeleteCustomerAddressMutation = {
  __typename: 'Mutation',
  deleteCustomerAddress: true,
};

export const CheckoutDeleteAddressModal = ({ id, isOpen, closeModal, title }: CheckoutDeleteAddressModalProps) => {
  const [deleteCustomerAddress] = useDeleteCustomerAddressMutation({
    onCompleted: closeModal,
    update: updateDeleteCustomerAddress(id),
  });

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      title={title}
      okHandler={() => deleteCustomerAddress({ variables: { id }, optimisticResponse })}
      cancelHandler={closeModal}
      cancelButtonLabel="Cancelar"
      okButtonLabel="Excluir"
      showCancelButton
      backdropClick={false}
    />
  );
};

export default CheckoutDeleteAddressModal;
