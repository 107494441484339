export const formatPhoneNumber = (number?: string | null): string => {
  if (!number) return '';

  const trimNumber = number.replace(/[^\d]/g, '');
  switch (trimNumber.length) {
    case 10:
      return trimNumber.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    case 11:
      return trimNumber.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  }
  return trimNumber;
};

export default formatPhoneNumber;
