import { yupResolver } from '@hookform/resolvers/yup';
import {
  Col,
  Container,
  Form,
  getCardCVCLength,
  getCardCVCMask,
  getCardMask,
  Help24,
  Input,
  Modal,
  Row,
  TextfieldCommonMasks,
  Tooltip,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import { useEffect, useMemo, useState } from 'react';
import Cards from 'react-credit-cards';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  CardValidation,
  CreateCreditCardTokenMutation,
  PaymentDocumentType,
  useCreateCreditCardTokenMutation,
  useGetCustomerDataQuery,
  useUpdateCreditCardMutation,
} from '~/operations';
import { createCreditCard as createCreditCardUpdater } from '~/state/cache/update';
import mapCardType from '~/utils/mapCardType';
import Yup from '~/utils/yup';
import styles from './VindiEditOrAddCardModal.module.scss';
import { useAlertsContext } from '~/utils/useAlerts';

type CardFormData = {
  number: string;
  username: string;
  expiration: string;
  cvc: string;
  issuer: string;
};

export interface VindiEditOrAddCardModalProps {
  isOpen: boolean;
  closeModal: () => void;
  sendModal: () => void;
  defaultValues?: CardFormData;
  title: string;
  onSelect?: Function;
  subscriptionId?: string;
  okButtonLabel?: string;
  selectedBillingAddress?: any;
}

type FocusedField = 'number' | 'name' | 'cvc' | 'expiry' | undefined;

const schema = Yup.object().shape({
  number: Yup.string().ccNumber('Número de cartão inválido').required('Campo é obrigatório'),
  username: Yup.string().required('Campo é obrigatório'),
  expiration: Yup.string().ccExpirationDate('Data inválida').required('Campo é obrigatório'),
  cvc: Yup.string().ccSecurityNumber('CVC é inválido').required('Campo é obrigatório'),
});

const handleErrorToCreateCreditCard = (data: CreateCreditCardTokenMutation | null | undefined) => {
  if (data?.createCreditCardToken?.errors?.[0]?.code === 'PESx129') {
    return 'Cartões de crédito de uso único não são válidos para o serviço de assinaturas ZiYou. Por favor, insira um cartão válido.';
  } else {
    return 'Infelizmente não foi possível adicionar o cartão. Por favor, verifique as informações ou entre em contato com a nossa central de atendimento.';
  }
};

export const VindiEditOrAddCardModal = ({
  defaultValues,
  isOpen,
  closeModal,
  sendModal,
  title,
  onSelect,
  subscriptionId,
  okButtonLabel = 'Salvar',
  selectedBillingAddress,
}: VindiEditOrAddCardModalProps) => {
  const { actions: alertActions } = useAlertsContext();
  const [createCreditCardToken, { loading }] = useCreateCreditCardTokenMutation();
  const [updateCreditCard, { loading: updating }] = useUpdateCreditCardMutation();
  const [errorAlert, setErrorAlert] = useState<string>();
  const { data: customerData } = useGetCustomerDataQuery({
    errorPolicy: 'all',
  });

  const { register, errors, watch, setValue, handleSubmit } = useForm<CardFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const selectedAddress =
    selectedBillingAddress ||
    customerData?.customer?.addresses?.find((a) => a?.default_billing) ||
    customerData?.customer?.addresses?.[0];

  const onSubmit: SubmitHandler<CardFormData> = async (values) => {
    try {
      const variables = {
        number: values.number.replace(/[^\d]/g, ''),
        holder: values.username,
        expirationDate: values.expiration,
        securityCode: values.cvc,
        issuer: mapCardType(values.issuer) || 'Visa',
        isDefault: false,
        validation: subscriptionId ? CardValidation.Double : CardValidation.Simple,
        customer: {
          firstName: customerData?.customer?.firstname || '',
          lastName: customerData?.customer?.lastname || '',
          email: customerData?.customer?.email || '',
          documentNumber: customerData?.customer?.documentNumber || '',
          address: {
            street: selectedAddress.street[0],
            number: selectedAddress.number,
            complement: selectedAddress.complement,
            postalCode: selectedAddress.postcode,
            neighborhood: selectedAddress.neighborhood,
            city: selectedAddress.city,
            state: selectedAddress.region.region_code,
            country: selectedAddress.country_code,
          },
          documentType:
            customerData?.customer?.documentType === PaymentDocumentType.Cnpj
              ? PaymentDocumentType.Cnpj
              : PaymentDocumentType.Cpf,
          phone: customerData?.customer?.phoneNumber || '',
        },
      };

      const ret = await createCreditCardToken({ variables, update: createCreditCardUpdater(variables) });
      const token = ret.data?.createCreditCardToken?.edge;

      if (!token) {
        setErrorAlert(handleErrorToCreateCreditCard(ret.data));
        return false;
      }

      if (subscriptionId) {
        // In case we pass a subscription ID, we also want to update the subscription to use this token
        const updateResult = await updateCreditCard({ variables: { id: subscriptionId, vaultId: token.id } });
        if (!updateResult.data?.updateSubscriptionCreditCard?.success) {
          setErrorAlert(
            'Infelizmente não foi possível adicionar o cartão. Por favor, verifique as informações ou entre em contato com a nossa central de atendimento.'
          );
          return false;
        }
      }

      onSelect &&
        onSelect({
          __typename: 'CreditCardToken',
          id: token.id,
          number: variables.number,
          holder: variables.holder,
          expirationDate: variables.expirationDate,
          securityCode: variables.securityCode,
          issuer: variables.issuer,
          isDefault: true,
        });
      sendModal();
    } catch (error) {
      const authenticationError = error?.graphQLErrors?.find((errors) => errors.message === 'Not Authorised!');
      if (authenticationError) {
        alertActions.addDangerAlert('Sua sessão expirou, por favor, logar novamente');
      }
    }
  };

  const [focusedField, setFocusedField] = useState<FocusedField>(undefined);
  const cvc = watch('cvc') || '';
  const number = watch('number') || '';
  const username = watch('username') || '';
  const expiration = watch('expiration') || '';
  const issuer = watch('issuer') || '';

  useEffect(() => {
    errorAlert && setErrorAlert(undefined); // remove alert after editing some field
  }, [number, cvc, username, expiration]);

  const formatedExpiration = useMemo(() => {
    const number = expiration.replaceAll(/[^\d]/g, '');
    return number.substr(0, 2) + '/' + number.substr(4, 2);
  }, [expiration]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      cancelButtonLabel="Cancelar"
      okButtonLabel={okButtonLabel}
      showCancelButton
      size="lg"
      okDisabled={loading || updating}
    >
      <Form>
        <Container>
          <Row className="no-x-margin-md">
            <Col lg={7} className="no-x-padding-md">
              <Input
                label="Número"
                name="number"
                className="fs-exclude"
                placeholder="0000 0000 0000 0000"
                mask={getCardMask(issuer)}
                register={register}
                onFocus={() => setFocusedField('number')}
                onBlur={() => setFocusedField(undefined)}
                status={errors.number ? 'danger' : 'default'}
                helperText={errors.number?.message ?? ''}
                error={!!errors.number?.message}
                setValue={setValue}
                isCreditCard
              />
              <Input
                label="Nome"
                name="username"
                placeholder="Nome e sobrenome"
                ref={register}
                onFocus={() => setFocusedField('name')}
                onBlur={() => setFocusedField(undefined)}
                status={errors.username ? 'danger' : 'default'}
                helperText={errors.username?.message ?? ''}
                error={!!errors.username?.message}
              />
            </Col>
            <Col lg={5} className="no-x-padding-md">
              <div className={styles['checkout-card-modal__card-component'] + ' fs-exclude'}>
                <Cards
                  cvc={cvc.substring(0, getCardCVCLength(issuer))}
                  expiry={formatedExpiration}
                  name={username}
                  number={number}
                  focused={focusedField}
                  placeholders={{ name: 'Seu nome' }}
                  callback={({ issuer }) => setValue('issuer', issuer)}
                />
                <input type="hidden" name="issuer" ref={register} />
              </div>
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={4} className="no-x-padding-md">
              <Input
                label="Data de validade"
                name="expiration"
                mask={TextfieldCommonMasks.cardExpireDate}
                placeholder="MM/AAAA"
                ref={register}
                register={register}
                onFocus={() => setFocusedField('expiry')}
                onBlur={() => setFocusedField(undefined)}
                status={errors.expiration ? 'danger' : 'default'}
                helperText={errors.expiration?.message ?? ''}
                error={!!errors.expiration?.message}
              />
            </Col>
            <Col lg={4} className="no-x-padding-md">
              <div className={styles['checkout-card-modal__cvc-wrapper']}>
                <Input
                  label="CVC"
                  name="cvc"
                  className="fs-exclude"
                  placeholder="000"
                  mask={getCardCVCMask(issuer)}
                  register={register}
                  onFocus={() => setFocusedField('cvc')}
                  onBlur={() => setFocusedField(undefined)}
                  status={errors.cvc ? 'danger' : 'default'}
                  helperText={errors.cvc?.message ?? ''}
                  error={!!errors.cvc?.message}
                />
                <Tooltip
                  id="help-cvc"
                  text="CVV é um código de segurança de 3 dígitos, impresso no verso de cartões de crédito. Importante: Em cartões American Express você encontra o CVV com 4 dígitos e na frente."
                >
                  <Help24 id="help-cvc" className={styles['checkout-card-modal__cvc-icon']} />
                </Tooltip>
              </div>
            </Col>
          </Row>
          {errorAlert && (
            <Row>
              <Col>
                <Typography className="color-red-ziyou">{errorAlert}</Typography>
              </Col>
            </Row>
          )}
        </Container>
      </Form>
    </Modal>
  );
};

export default VindiEditOrAddCardModal;
