import { Modal } from '@objectedge/ziyou-storefront-ds';
import { useDeleteCreditCardTokenMutation } from '~/operations';
import { deleteCreditCardToken as deleteCreditCardTokenUpdater } from '~/state/cache/update';

export interface VindiDeleteCardModalProps {
  id: string;
  isOpen: boolean;
  closeModal: () => void;
  title: string;
}

export const VindiDeleteCardModal = ({ id, isOpen, closeModal, title }: VindiDeleteCardModalProps) => {
  const [deleteCreditCardToken] = useDeleteCreditCardTokenMutation({
    variables: { id },
    update: deleteCreditCardTokenUpdater(id),
  });

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      title={title}
      okHandler={() => {
        deleteCreditCardToken().then(() => closeModal());
      }}
      cancelHandler={closeModal}
      cancelButtonLabel="Cancelar"
      okButtonLabel="Excluir"
      showCancelButton
      backdropClick={false}
    />
  );
};

export default VindiDeleteCardModal;
