import { PendingCircle24, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import styles from './ErrorText.module.scss';

export interface ErrorTextProps {
  hasTheError?: boolean;
  hasNotValidated?: boolean;
}

export const ErrorText: React.FC<ErrorTextProps> = ({ children, hasTheError, hasNotValidated }) => (
  <div className={styles['error-text']}>
    <PendingCircle24
      className={clsx(
        styles['error-text__icon'],
        hasTheError && styles['error-text__icon--has-error'],
        hasNotValidated && styles['error-text__icon--has-not-validated']
      )}
    />
    <Typography
      variant="caption-1"
      className={clsx(
        styles['error-text__text'],
        hasTheError && styles['error-text__text--has-error'],
        hasNotValidated && styles['error-text__text--has-not-validated']
      )}
    >
      {children}
    </Typography>
  </div>
);

export default ErrorText;
