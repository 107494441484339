import { ArrowDown24, ArrowUp24, Close32, Loader, QuantityInput, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { formatCurrency } from '~/utils/format/currency';
import { getSubscriptionPlanValue } from '~/utils/product';
import { useAlertsContext } from '~/utils/useAlerts';
import { useCartContext } from '~/utils/useCart';
import { CartContentOptionsModal } from '../CartContentOptionsModal';
import styles from './CartContent.module.scss';

interface RowHeight {
  [key: string]: number;
}

export const CartContent = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [inactiveRowsHeight, setInactiveRowsHeight] = useState<RowHeight>({});
  const { actions: alertsActions } = useAlertsContext();
  const { state: cartData, actions: cartActions } = useCartContext();
  const [activeDetails, setActiveDetails] = useState<Array<number>>([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(false);
  }, [cartData, alertsActions]);

  const toggleActiveDetails = useCallback(
    (productID: number) => {
      setActiveDetails((previousState) =>
        previousState.includes(productID)
          ? previousState.filter((registeredProductID) => registeredProductID !== productID)
          : [...previousState, productID]
      );
    },
    [setActiveDetails]
  );

  const removeProductFromCart = useCallback(
    (productID: number) => {
      cartActions.removeFromCart(productID);
      alertsActions.addWarningAlert('Item removido do carrinho', { id: 'cart_item_removed' });
      setActiveDetails((previousState) =>
        previousState.filter((registeredProductID) => registeredProductID !== productID)
      );
    },
    [cartActions.removeFromCart, setActiveDetails]
  );

  useEffect(() => {
    if (!tableRef.current) return;
    const rows: Array<HTMLTableRowElement> = Array.from(tableRef.current.querySelectorAll('tbody tr'));
    const heights = Object.fromEntries(rows.map((row) => [row.getAttribute('data-id'), row.offsetHeight - 2]));
    setInactiveRowsHeight((previousState) => ({ ...previousState, ...heights }));
  }, [activeDetails, tableRef]);

  return (
    <table className={styles['cart']} ref={tableRef}>
      {loader ? <Loader message="Carregando carrinho..." full={true} /> : null}
      <thead>
        <tr className={styles['cart__row-header']}>
          <th className={styles['cart__column-header']}></th>
          <th className={styles['cart__column-header']}>
            <Typography variant="caption-2" className={styles['cart__column-header-text']}>
              Item
            </Typography>
          </th>
          <th className={styles['cart__column-header']}>
            <Typography variant="caption-2" className={styles['cart__column-header-text']}>
              Quantidade
            </Typography>
          </th>
          <th className={styles['cart__column-header']}>
            <Typography variant="caption-2" className={styles['cart__column-header-text']}>
              Mensalidade
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {cartData.products.map((product) => (
          <Fragment key={product.id}>
            <tr
              data-component-id="cart-commerce-item-row"
              className={clsx(
                styles['cart__row'],
                product.status === 'inactive' && styles['cart__row--inactive'],
                activeDetails.includes(product.id) && styles['cart__row--without-border']
              )}
              data-id={product.id}
            >
              <td className={clsx(styles['cart__column'], styles['cart__image-column'])}>
                <Link href={`/produto/${product.parent_url_key || product.url_key}`}>
                  <a className={styles['cart__product-image-wrapper']}>
                    <img src={product.image} alt={product.name} className={styles['cart__product-image']} />
                  </a>
                </Link>
              </td>
              <td className={clsx(styles['cart__column'], styles['cart__info-column'])}>
                <div className={styles['cart__info-column-wrapper']}>
                  <div>
                    <Link href={`/produto/${product.parent_url_key || product.url_key}`}>
                      <a>
                        <Typography variant="h6" className={styles['cart__product-name']}>
                          {product.name}
                        </Typography>
                      </a>
                    </Link>
                    <Typography variant="h6" className={styles['cart__product-type']}>
                      {product.type}
                    </Typography>
                    <Typography variant="h5" className={styles['cart__product-value']}>
                      {`${formatCurrency(product.promotionalValue || product.value)}/m`}
                    </Typography>
                    {product.details.length > 0 && (
                      <div
                        data-component-id="cart-commerce-item-details-handle"
                        className={styles['cart__product-details-wrapper']}
                        onClick={() => toggleActiveDetails(product.id)}
                      >
                        <Typography variant="button" className={styles['cart__product-details-button-text']}>
                          Detalhes
                        </Typography>
                        {activeDetails.includes(product.id) ? (
                          <ArrowUp24 className={styles['cart__product-details-button-icon']} />
                        ) : (
                          <ArrowDown24 className={styles['cart__product-details-button-icon']} />
                        )}
                      </div>
                    )}
                  </div>
                  <CartContentOptionsModal
                    toggleDetails={() => toggleActiveDetails(product.id)}
                    onDelete={() => removeProductFromCart(product.id)}
                  />
                </div>
              </td>
              <td className={clsx(styles['cart__column'], styles['cart__input-column'])}>
                <div className={styles['cart__qty-input']}>
                  <QuantityInput
                    counter={product.cartQty}
                    onIncreaseCount={() => {
                      cartActions.increaseProductQty(product.id);
                      setLoader(true);
                    }}
                    onDecreaseCount={() => {
                      cartActions.decreaseProductQty(product.id);
                      setLoader(true);
                    }}
                    onManualInputCount={(e) => cartActions.changeProductQty(product.id, parseInt(e.target.value))}
                    onLazyManualInputCount={(e) => {
                      cartActions.lazyChangeProductQty(product.id, parseFloat(e.target.value));
                    }}
                    errorLabel={
                      product.cartQty > product.stockQty
                        ? `Atenção, quantidade indisponível. Temos apenas ${product.stockQty} em estoque.`
                        : ''
                    }
                  />
                </div>
              </td>
              <td className={clsx(styles['cart__column'], styles['cart__values-column'])}>
                <div className={styles['cart__value']}>
                  <div className={styles['cart__value-wrapper']}>
                    {product.promotionalValue ? (
                      <>
                        <Typography
                          variant="h5"
                          className={clsx(styles['cart__normal-value'], styles['cart__normal-value--has-promotional'])}
                        >
                          {`De ${formatCurrency(product.value * product.cartQty)}/m por`}
                        </Typography>
                        <Typography variant="h4" className={styles['cart__promotional-value']}>
                          {`${formatCurrency(product.promotionalValue * product.cartQty)}/m`}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="h4" className={clsx(styles['cart__normal-value--only'])}>
                        {`${formatCurrency(product.value * product.cartQty)}/m`}
                      </Typography>
                    )}
                    {!!product?.hasSelectedPlan && (
                      <Typography variant="body-text-1" className="mt-1">
                        {getSubscriptionPlanValue(product?.customizable_options)}
                      </Typography>
                    )}
                  </div>
                  <Close32
                    className={styles['cart__remove-icon']}
                    onClick={() => {
                      setLoader(true);
                      removeProductFromCart(product.id);
                    }}
                  />
                </div>
              </td>
              {product.status === 'inactive' && (
                <td
                  className={styles['cart__inactive-row']}
                  style={{ height: `${inactiveRowsHeight[product.id] || 0}px` }}
                >
                  <Typography variant="h3" className={styles['cart__inactive-row-text']}>
                    Item indisponível
                  </Typography>
                </td>
              )}
            </tr>
            {activeDetails.includes(product.id) && (
              <tr className={styles['cart__details-row']}>
                <td></td>
                <td colSpan={3}>
                  <Typography variant="h5" className={styles['cart__details-title']}>
                    Requisitos mínimos para instalação
                  </Typography>
                  <ul className={styles['cart__details-wrapper']}>
                    {product.details.map(({ label, value }) => (
                      <li className={styles['cart__details']}>
                        <Typography variant="h5" className={styles['cart__details-name']}>
                          {label}
                        </Typography>
                        <hr className={styles['cart__details-line']} />
                        <Typography variant="h6" className={styles['cart__details-value']}>
                          {value}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default CartContent;
