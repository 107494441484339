import { Typography } from '@objectedge/ziyou-storefront-ds';
import styles from './TestInfo.module.scss';

export const TestInfo = () => (
  <div className={styles.testInfo}>
    <ul className={styles.list}>
      <li className={styles.item}>
        <Typography variant="body-text-1">Sua atividade física onde, quando e como você quiser.</Typography>
      </li>
      <li className={styles.item}>
        <Typography variant="body-text-1">
          Receba o seu equipamento onde você estiver* e faça a troca quando quiser.
        </Typography>
      </li>
      <li className={styles.item}>
        <Typography variant="body-text-1">Tenha acesso livre a centenas de conteúdos e aulas.</Typography>
      </li>
      <li className={styles.item}>
        <Typography variant="body-text-1">Mantenha-se em movimento. </Typography>
      </li>
    </ul>
    <Typography variant="body-text-1">*disponível para a cidade de São Paulo</Typography>
  </div>
);

export default TestInfo;
