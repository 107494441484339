import { Modal } from '@objectedge/ziyou-storefront-ds';
import { DeletePaymentTokenMutation, useDeletePaymentTokenMutation } from '~/operations';
import { deletePaymentToken as deletePaymentTokenUpdater } from '~/state/cache/update';

export interface CheckoutDeleteCardModalProps {
  publicHash: string;
  isOpen: boolean;
  closeModal: () => void;
  title: string;
}

const optimisticResponse: DeletePaymentTokenMutation = {
  __typename: 'Mutation',
  deletePaymentToken: { __typename: 'DeletePaymentTokenOutput', result: true },
};

export const CheckoutDeleteCardModal = ({ publicHash, isOpen, closeModal, title }: CheckoutDeleteCardModalProps) => {
  const [deletePaymentToken] = useDeletePaymentTokenMutation({
    variables: { hash: publicHash },
    update: deletePaymentTokenUpdater(publicHash),
  });

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      title={title}
      okHandler={() => {
        deletePaymentToken({ optimisticResponse });
        closeModal();
      }}
      cancelHandler={closeModal}
      cancelButtonLabel="Cancelar"
      okButtonLabel="Excluir"
      showCancelButton
      backdropClick={false}
    />
  );
};

export default CheckoutDeleteCardModal;
