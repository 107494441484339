import { yupResolver } from '@hookform/resolvers/yup';
import {
  Col,
  Container,
  Form,
  getCardCVCLength,
  getCardCVCMask,
  getCardMask,
  Help24,
  Input,
  Modal,
  Row,
  TextfieldCommonMasks,
  Tooltip,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import { useEffect, useMemo, useState } from 'react';
import Cards from 'react-credit-cards';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAddCreditCardMutation } from '~/operations';
import { addCreditCard as addCreditCardUpdater } from '~/state/cache/update';
import mapCardType from '~/utils/mapCardType';
import Yup from '~/utils/yup';
import styles from './CheckoutEditOrAddCardModal.module.scss';

interface CardFormData {
  number: string;
  username: string;
  expiration: string;
  cvc: string;
  issuer: string;
}

export interface CheckoutEditOrAddCardModalProps {
  isOpen: boolean;
  closeModal: () => void;
  sendModal: () => void;
  defaultValues?: CardFormData;
  title: string;
  onSelect?: Function;
  subscriptionId?: string;
  okButtonLabel?: string;
}

type FocusedField = 'number' | 'name' | 'cvc' | 'expiry' | undefined;

const schema = Yup.object().shape({
  number: Yup.string().ccNumber('Número de cartão inválido').required('Campo é obrigatório'),
  username: Yup.string().required('Campo é obrigatório'),
  expiration: Yup.string().ccExpirationDate('Data inválida').required('Campo é obrigatório'),
  cvc: Yup.string().ccSecurityNumber('CVC é inválido').required('Campo é obrigatório'),
});

export const CheckoutEditOrAddCardModal = ({
  defaultValues,
  isOpen,
  closeModal,
  sendModal,
  title,
  onSelect,
  subscriptionId,
  okButtonLabel = 'Salvar',
}: CheckoutEditOrAddCardModalProps) => {
  const [addCreditCard, { loading }] = useAddCreditCardMutation();
  const [errorAlert, setErrorAlert] = useState<string>();

  const { register, errors, watch, setValue, handleSubmit } = useForm<CardFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<CardFormData> = async (values) => {
    const variables = {
      number: values.number.replace(/[^\d]/g, ''),
      holder: values.username,
      expirationDate: values.expiration,
      securityCode: values.cvc,
      type: mapCardType(values.issuer) || 'Visa',
      subscriptionId,
    };

    const ret = await addCreditCard({ variables, update: addCreditCardUpdater(variables) });

    if (ret.data?.addCreditCard?.errors?.length) {
      setErrorAlert(
        'Infelizmente não foi possível adicionar o cartão. Por favor, verifique as informações ou entre em contato com a nossa central de atendimento.'
      );
    } else {
      onSelect &&
        onSelect({
          __typename: 'PaymentToken',
          details: JSON.stringify({
            Number: '************' + variables.number.substr(variables.number.length - 4),
            ExpirationDate: variables.expirationDate,
            type: variables.type,
            Holder: variables.holder,
          }),
          isDefault: true,
          payment_method_code: 'ziyou_braspag',
          public_hash: ret.data?.addCreditCard?.id,
          type: 'card',
        });
      sendModal();
    }
    return false;
  };

  const [focusedField, setFocusedField] = useState<FocusedField>(undefined);
  const cvc = watch('cvc') || '';
  const number = watch('number') || '';
  const username = watch('username') || '';
  const expiration = watch('expiration') || '';
  const issuer = watch('issuer') || '';

  useEffect(() => {
    errorAlert && setErrorAlert(undefined); // remove alert after editing some field
  }, [number, cvc, username, expiration]);

  const formatedExpiration = useMemo(() => {
    const number = expiration.replaceAll(/[^\d]/g, '');
    return number.substr(0, 2) + '/' + number.substr(4, 2);
  }, [expiration]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      okHandler={handleSubmit(onSubmit)}
      cancelHandler={closeModal}
      cancelButtonLabel="Cancelar"
      okButtonLabel={okButtonLabel}
      showCancelButton
      size="lg"
      okDisabled={loading}
    >
      <Form>
        <Container>
          <Row className="no-x-margin-md">
            <Col lg={7} className="no-x-padding-md">
              <Input
                label="Número"
                name="number"
                className="fs-exclude"
                placeholder="0000 0000 0000 0000"
                mask={getCardMask(issuer)}
                register={register}
                setValue={setValue}
                isCreditCard
                onFocus={() => setFocusedField('number')}
                onBlur={() => setFocusedField(undefined)}
                status={errors.number ? 'danger' : 'default'}
                helperText={errors.number?.message ?? ''}
                error={!!errors.number?.message}
              />
              <Input
                label="Nome"
                name="username"
                placeholder="Nome e sobrenome"
                ref={register}
                onFocus={() => setFocusedField('name')}
                onBlur={() => setFocusedField(undefined)}
                status={errors.username ? 'danger' : 'default'}
                helperText={errors.username?.message ?? ''}
                error={!!errors.username?.message}
              />
            </Col>
            <Col lg={5} className="no-x-padding-md">
              <div className={styles['checkout-card-modal__card-component'] + ' fs-exclude'}>
                <Cards
                  cvc={cvc.substring(0, getCardCVCLength(issuer))}
                  expiry={formatedExpiration}
                  name={username}
                  number={number}
                  focused={focusedField}
                  placeholders={{ name: 'Seu nome' }}
                  callback={({ issuer }) => setValue('issuer', issuer)}
                />
                <input type="hidden" name="issuer" ref={register} />
              </div>
            </Col>
          </Row>
          <Row className="no-x-margin-md">
            <Col lg={4} className="no-x-padding-md">
              <Input
                label="Data de validade"
                name="expiration"
                mask={TextfieldCommonMasks.cardExpireDate}
                placeholder="MM/AAAA"
                ref={register}
                register={register}
                onFocus={() => setFocusedField('expiry')}
                onBlur={() => setFocusedField(undefined)}
                status={errors.expiration ? 'danger' : 'default'}
                helperText={errors.expiration?.message ?? ''}
                error={!!errors.expiration?.message}
              />
            </Col>
            <Col lg={4} className="no-x-padding-md">
              <div className={styles['checkout-card-modal__cvc-wrapper']}>
                <Input
                  label="CVC"
                  name="cvc"
                  className="fs-exclude"
                  placeholder="000"
                  mask={getCardCVCMask(issuer)}
                  register={register}
                  onFocus={() => setFocusedField('cvc')}
                  onBlur={() => setFocusedField(undefined)}
                  status={errors.cvc ? 'danger' : 'default'}
                  helperText={errors.cvc?.message ?? ''}
                  error={!!errors.cvc?.message}
                />
                <Tooltip
                  id="help-cvc"
                  text="CVV é um código de segurança de 3 dígitos, impresso no verso de cartões de crédito. Importante: Em cartões American Express você encontra o CVV com 4 dígitos e na frente."
                >
                  <Help24 id="help-cvc" className={styles['checkout-card-modal__cvc-icon']} />
                </Tooltip>
              </div>
            </Col>
          </Row>
          {errorAlert && (
            <Row>
              <Col>
                <Typography className="color-red-ziyou">{errorAlert}</Typography>
              </Col>
            </Row>
          )}
        </Container>
      </Form>
    </Modal>
  );
};

export default CheckoutEditOrAddCardModal;
