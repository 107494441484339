import {
  ArrowDown24,
  ArrowUp24,
  Checkbox,
  Col,
  Input,
  Row,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import styles from './RegisterLegalPersonForm.module.scss';

export interface RegisterLegalPersonFormProps {
  register: any;
  errors: any;
}

const hasError = (errors) => {
  const fields = ['phone', 'companyName', 'tradingName', 'cnpj', 'stateRegistration', 'nameOfTheRepresentative'];
  return Object.keys(errors).some((k) => fields.includes(k));
};

export const RegisterLegalPersonForm = ({ register, errors }: RegisterLegalPersonFormProps) => {
  const [showForm, setShowForm] = useState(true);

  const rowClasses = clsx(!showForm && styles['content--hidden']);

  useEffect(() => {
    if (hasError(errors)) setShowForm(true);
  }, [errors]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <div
            onClick={() => setShowForm(!showForm)}
            className={clsx(
              styles['section-title'],
              showForm && styles['section-title--active'],
              !showForm && styles['section-title--inactive']
            )}
          >
            <Typography
              variant="h4"
              className={clsx(
                styles['section-title__text'],
                showForm && styles['section-title__text--active'],
                !showForm && styles['section-title__text--inactive']
              )}
            >
              Dados pessoais
            </Typography>
            {showForm ? (
              <ArrowDown24 className={clsx(styles['section-title__icon'], styles['section-title__icon--active'])} />
            ) : (
              <ArrowUp24 className={clsx(styles['section-title__icon'], styles['section-title__icon--inactive'])} />
            )}
          </div>
        </Col>
      </Row>
      <Row className={clsx(rowClasses, styles['section-title__first-row'])}>
        <Col lg={5}>
          <Input
            label="Razão Social"
            name="companyName"
            placeholder="Razão Social"
            status={errors.companyName ? 'danger' : 'default'}
            helperText={errors.companyName?.message ?? ''}
            error={!!errors.companyName?.message}
            ref={register}
          />
        </Col>
        <Col lg={5}>
          <Input
            label="Nome fantasia"
            name="tradingName"
            placeholder="Nome fantasia"
            status={errors.tradingName ? 'danger' : 'default'}
            helperText={errors.tradingName?.message ?? ''}
            error={!!errors.tradingName?.message}
            ref={register}
          />
        </Col>
        <Col lg={2}>
          <Input
            label="CNPJ"
            name="cnpj"
            placeholder="00.000.000/0000-00"
            status={errors.cnpj ? 'danger' : 'default'}
            helperText={errors.cnpj?.message ?? ''}
            error={!!errors.cnpj?.message}
            register={register}
            mask={TextfieldCommonMasks.cnpj}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={4}>
          <Input
            label={
              <>
                Inscrição Municipal <span className={styles['optional']}>(opcional)</span>
              </>
            }
            name="municipalRegistration"
            placeholder="000000000"
            status={errors.municipalRegistration ? 'danger' : 'default'}
            helperText={errors.municipalRegistration?.message ?? ''}
            error={!!errors.municipalRegistration?.message}
            ref={register}
          />
        </Col>
        <Col lg={4}>
          <Input
            label="Inscrição Estadual"
            name="stateRegistration"
            placeholder="000000000"
            status={errors.stateRegistration ? 'danger' : 'default'}
            helperText={errors.stateRegistration?.message ?? ''}
            error={!!errors.stateRegistration?.message}
            ref={register}
          />
        </Col>
        <Col lg={4}>
          <Checkbox
            label="Isento"
            name="stateRegistrationExempt"
            id="registration-tax-excempt"
            innerRef={register}
            className="mt-5"
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={6}>
          <Input
            label="Nome do representante"
            name="nameOfTheRepresentative"
            placeholder="Seu nome"
            status={errors.nameOfTheRepresentative ? 'danger' : 'default'}
            helperText={errors.nameOfTheRepresentative?.message ?? ''}
            error={!!errors.nameOfTheRepresentative?.message}
            ref={register}
          />
        </Col>
        <Col lg={3}>
          <Input
            label="Seu telefone"
            name="phone"
            placeholder="(00) 00000-0000"
            status={errors.phone ? 'danger' : 'default'}
            helperText={errors.phone?.message ?? ''}
            error={!!errors.phone?.message}
            register={register}
            mask={TextfieldCommonMasks.phone}
          />
        </Col>
        <Col lg={3}>
          <Input
            label={
              <>
                Outro telefone <span className={styles['optional']}>(opcional)</span>
              </>
            }
            name="anotherPhone"
            placeholder="(00) 00000-0000"
            status={errors.anotherPhone ? 'danger' : 'default'}
            helperText={errors.anotherPhone?.message ?? ''}
            error={!!errors.anotherPhone?.message}
            register={register}
            mask={TextfieldCommonMasks.phone}
          />
        </Col>
      </Row>
    </>
  );
};

export default RegisterLegalPersonForm;
