import { Club24, Typography } from '@objectedge/ziyou-storefront-ds';
import Display from '~/utils/Display';
import styles from './FreeSubscriptionCallout.module.scss';

export interface FreeSubscriptionCalloutProps {
  className?: string;
  title?: string;
  subTitle?: string;
  image?: string;
}

export const FreeSubscriptionCallout = ({ className, title, subTitle, image }: FreeSubscriptionCalloutProps) => (
  <div className={styles.welcome}>
    <div className={styles.content}>
      <Display>
        <div className={styles.responsiveHeader}>
          <Club24 />
        </div>
      </Display>
      <Typography variant="h1" className="d-none d-lg-block d-md-block">
        {title}
      </Typography>
      <Typography variant="h2" className="d-block d-lg-none d-md-none">
        {title}
      </Typography>
      <Typography variant="body-text-1" className="mb-0">
        {subTitle}
      </Typography>
    </div>
    <div className={styles['confirmation-image']}>
      <img src={image || ''} className={styles['image-container']} />
    </div>
  </div>
);

export default FreeSubscriptionCallout;
