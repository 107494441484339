import {
  ArrowDown24,
  ArrowUp24,
  Col,
  Input,
  Row,
  Select,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useState } from 'react';
import styles from './RegisterUserAddressForm.module.scss';

export interface RegisterUserAddressFormProps {
  register: any;
  errors: any;
  setValue: any;
  addressState: { label: string; value: string };
}

export const RegisterUserAddressForm = ({ register, errors, setValue, addressState }: RegisterUserAddressFormProps) => {
  const [showForm, setShowForm] = useState(false);
  const rowClasses = clsx(!showForm && styles['content--hidden']);

  return (
    <>
      <Row>
        <Col lg={12}>
          <div
            onClick={() => setShowForm(!showForm)}
            className={clsx(
              styles['section-title'],
              showForm && styles['section-title--active'],
              !showForm && styles['section-title--inactive']
            )}
          >
            <Typography
              variant="h4"
              className={clsx(
                styles['section-title__text'],
                showForm && styles['section-title__text--active'],
                !showForm && styles['section-title__text--inactive']
              )}
            >
              Endereço
            </Typography>
            {showForm ? (
              <ArrowDown24 className={clsx(styles['section-title__icon'], styles['section-title__icon--active'])} />
            ) : (
              <ArrowUp24 className={clsx(styles['section-title__icon'], styles['section-title__icon--inactive'])} />
            )}
          </div>
        </Col>
      </Row>
      <Row className={clsx(rowClasses, styles['section-title__first-row'])}>
        <Col lg={8}>
          <Input
            label="Nome do endereço"
            name="addressName"
            placeholder="Ex: Minha casa, trabalho, etc"
            error={!!errors.addressName?.message}
            ref={register}
          />
        </Col>
        <Col lg={4}>
          <Input
            label="CEP"
            name="addressCEP"
            placeholder="00000-000"
            mask={TextfieldCommonMasks.cep}
            register={register}
            error={!!errors.addressCEP?.message}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={12}>
          <Input
            label="Endereço"
            name="addressStreet"
            placeholder="Rua ..."
            maxLength={54}
            error={!!errors.addressStreet?.message}
            ref={register}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={6}>
          <Input
            label="Número"
            name="addressNumber"
            placeholder="nº"
            maxLength={5}
            error={!!errors.addressNumber?.message}
            ref={register}
          />
        </Col>
        <Col lg={6}>
          <Input
            label="Complemento"
            name="addressComplement"
            placeholder="Aptº, bloco, quadra..."
            maxLength={14}
            error={!!errors.addressComplement?.message}
            ref={register}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={5}>
          <Input
            label="Bairro"
            name="addressNeighborhood"
            placeholder="Bairro"
            maxLength={45}
            error={!!errors.addressNeighborhood?.message}
            ref={register}
          />
        </Col>
        <Col lg={5}>
          <Input
            label="Cidade"
            name="addressCity"
            placeholder="Cidade"
            maxLength={50}
            error={!!errors.addressCity?.message}
            ref={register}
          />
        </Col>
        <Col lg={2}>
          <Select
            label="Estado"
            placeholder="UF"
            value={addressState}
            onChange={(value) => setValue('addressState', value)}
            options={[
              { label: 'AC', value: '485' },
              { label: 'AL', value: '486' },
              { label: 'AP', value: '487' },
              { label: 'AM', value: '488' },
              { label: 'BA', value: '489' },
              { label: 'CE', value: '490' },
              { label: 'ES', value: '491' },
              { label: 'GO', value: '492' },
              { label: 'MA', value: '493' },
              { label: 'MT', value: '494' },
              { label: 'MS', value: '495' },
              { label: 'MG', value: '496' },
              { label: 'PA', value: '497' },
              { label: 'PB', value: '498' },
              { label: 'PR', value: '499' },
              { label: 'PE', value: '500' },
              { label: 'PI', value: '501' },
              { label: 'RJ', value: '502' },
              { label: 'RN', value: '503' },
              { label: 'RS', value: '504' },
              { label: 'RO', value: '505' },
              { label: 'RR', value: '506' },
              { label: 'SC', value: '507' },
              { label: 'SP', value: '508' },
              { label: 'SE', value: '509' },
              { label: 'TO', value: '510' },
              { label: 'DF', value: '511' },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default RegisterUserAddressForm;
