import { Button, ButtonVariant, More24, Typography } from '@objectedge/ziyou-storefront-ds';
import { MutableRefObject, useState } from 'react';
import { CheckoutEditOrAddCardModal } from '../CheckoutEditOrAddCardModal';
import { PaymentCard } from '../PaymentCard';
import styles from './CheckoutCardSelection.module.scss';

export interface CheckoutCardSelectionProps {
  payments: any;
  selectedCard: any;
  selectCard: Function;
  cardRef: MutableRefObject<any>;
}

export const CheckoutCardSelection = ({ selectedCard, payments, selectCard, cardRef }: CheckoutCardSelectionProps) => {
  const [createModal, setCreateModal] = useState(false);

  return (
    <div className={styles['checkout-card-selection']} ref={cardRef}>
      <Typography variant="h4" className={styles['checkout-card-selection__title']}>
        Cartão de crédito
      </Typography>
      <ul className={styles.cardOptions}>
        {payments.map((cardOption) => (
          <PaymentCard
            key={cardOption.public_hash}
            cardOption={cardOption}
            onSelect={selectCard}
            selected={selectedCard?.public_hash === cardOption.public_hash}
          />
        ))}
      </ul>
      <Button
        variant={ButtonVariant.SECONDARY}
        className={styles['checkout-card-selection__add-more']}
        onClick={() => setCreateModal(true)}
      >
        <More24 className={styles['checkout-card-selection__add-more-icon']} />
        <Typography variant="button" className={styles['checkout-card-selection__add-more-text']}>
          Adicionar novo
        </Typography>
      </Button>
      <CheckoutEditOrAddCardModal
        isOpen={createModal}
        sendModal={() => setCreateModal(!createModal)}
        closeModal={() => setCreateModal(!createModal)}
        title="Novo cartão"
        onSelect={selectCard}
      />
    </div>
  );
};

export default CheckoutCardSelection;
