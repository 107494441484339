import {
  ArrowDown24,
  ArrowUp24,
  Col,
  Input,
  Row,
  Select,
  TextfieldCommonMasks,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import styles from './RegisterPhysicalPersonForm.module.scss';

export interface RegisterPhysicalPersonFormProps {
  register: any;
  errors: any;
  setValue: any;
  gender: { label: string; value: string };
  genderRef: any;
}

const hasError = (errors) => {
  const fields = ['name', 'lastName', 'gender', 'dateOfBirth', 'cpf', 'phone'];
  return Object.keys(errors).some((k) => fields.includes(k));
};

export const RegisterPhysicalPersonForm = (props: RegisterPhysicalPersonFormProps) => {
  const { register, errors, setValue, gender, genderRef } = props;
  const [showForm, setShowForm] = useState(true);

  const rowClasses = clsx(!showForm && styles['content--hidden']);

  useEffect(() => {
    if (hasError(errors)) setShowForm(true);
  }, [errors]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <div
            onClick={() => setShowForm(!showForm)}
            className={clsx(
              styles['section-title'],
              showForm && styles['section-title--active'],
              !showForm && styles['section-title--inactive']
            )}
          >
            <Typography
              variant="h4"
              className={clsx(
                styles['section-title__text'],
                showForm && styles['section-title__text--active'],
                !showForm && styles['section-title__text--inactive']
              )}
            >
              Dados pessoais
            </Typography>
            {showForm ? (
              <ArrowDown24 className={clsx(styles['section-title__icon'], styles['section-title__icon--active'])} />
            ) : (
              <ArrowUp24 className={clsx(styles['section-title__icon'], styles['section-title__icon--inactive'])} />
            )}
          </div>
        </Col>
      </Row>
      <Row className={clsx(rowClasses, styles['section-title__first-row'])}>
        <Col lg={5}>
          <Input
            label="Nome"
            name="name"
            placeholder="Seu nome"
            status={errors.name ? 'danger' : 'default'}
            helperText={errors.name?.message ?? ''}
            error={!!errors.name?.message}
            ref={register}
            maxLength={30}
          />
        </Col>
        <Col lg={5}>
          <Input
            label="Sobrenome"
            name="lastName"
            placeholder="Sobrenome"
            status={errors.lastName ? 'danger' : 'default'}
            helperText={errors.lastName?.message ?? ''}
            error={!!errors.lastName?.message}
            ref={register}
            maxLength={50}
          />
        </Col>
        <Col lg={2}>
          <Select
            innerRef={genderRef}
            label="Sexo"
            id="registration-gender-select"
            placeholder="Selecione"
            value={gender}
            status={errors.gender ? 'danger' : 'default'}
            helperText={errors.gender?.message ?? ''}
            onChange={(value) => setValue('gender', value, { shouldValidate: true })}
            options={[
              { label: 'Masculino', value: '1' },
              { label: 'Feminino', value: '2' },
              { label: 'Prefiro não responder', value: '4' },
            ]}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={5}>
          <Input
            label="Data de Nascimento"
            name="dateOfBirth"
            placeholder="DD/MM/AAAA"
            status={errors.dateOfBirth ? 'danger' : 'default'}
            helperText={errors.dateOfBirth?.message ?? ''}
            error={!!errors.dateOfBirth?.message}
            register={register}
            mask={TextfieldCommonMasks.date}
          />
        </Col>
        <Col lg={5}>
          <Input
            label="CPF"
            name="cpf"
            placeholder="000.000.000-00"
            status={errors.cpf ? 'danger' : 'default'}
            helperText={errors.cpf?.message ?? ''}
            error={!!errors.cpf?.message}
            register={register}
            mask={TextfieldCommonMasks.cpf}
          />
        </Col>
      </Row>
      <Row className={rowClasses}>
        <Col lg={5}>
          <Input
            label="Seu telefone"
            name="phone"
            placeholder="(00) 00000-0000"
            status={errors.phone ? 'danger' : 'default'}
            helperText={errors.phone?.message ?? ''}
            error={!!errors.phone?.message}
            register={register}
            mask={TextfieldCommonMasks.phone}
          />
        </Col>
        <Col lg={5}>
          <Input
            label={
              <>
                Outro telefone <span className={styles['optional']}>(opcional)</span>
              </>
            }
            name="anotherPhone"
            placeholder="(00) 00000-0000"
            status={errors.anotherPhone ? 'danger' : 'default'}
            helperText={errors.anotherPhone?.message ?? ''}
            error={!!errors.anotherPhone?.message}
            register={register}
            mask={TextfieldCommonMasks.phone}
          />
        </Col>
      </Row>
    </>
  );
};

export default RegisterPhysicalPersonForm;
