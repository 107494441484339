import { Button, ButtonVariant, More24, Typography } from '@objectedge/ziyou-storefront-ds';
import { MutableRefObject, useContext, useEffect, useState } from 'react';
import { VindiPaymentCard } from '~/components/perfil/VindiPaymentCard';
import { CheckoutContext } from '~/context';
import { CreditCardTokenData } from '~/context/checkout.types';
import { VindiEditOrAddCardModal } from '../VindiEditOrAddCardModal';
import styles from './VindiCardSelection.module.scss';

export interface VindiCardSelectionProps {
  cardRef: MutableRefObject<any>;
  setVindiCard: (card: CreditCardTokenData) => void;
  vindiId: string | undefined;
  selectedBillingAddress: any;
}

export const VindiCardSelection = ({
  cardRef,
  setVindiCard,
  vindiId,
  selectedBillingAddress,
}: VindiCardSelectionProps) => {
  const [createModal, setCreateModal] = useState(false);
  const { availableCreditCards } = useContext(CheckoutContext);

  useEffect(() => {
    const card = availableCreditCards.find((item) => item.isDefault);
    if (card) setVindiCard(card);
    else if (availableCreditCards[0]) setVindiCard(availableCreditCards[0]);
  }, [availableCreditCards]);

  return (
    <div className={styles['checkout-card-selection']} ref={cardRef}>
      <Typography variant="h4" className={styles['checkout-card-selection__title']}>
        Cartão de crédito
      </Typography>
      <ul className={styles.cardOptions}>
        {availableCreditCards.map((token, ind) => (
          <VindiPaymentCard
            key={ind.toString()}
            onSelect={(card) => setVindiCard(card)}
            selected={vindiId === token.id}
            token={token}
            allowDelete
          />
        ))}
      </ul>
      <Button
        variant={ButtonVariant.SECONDARY}
        className={styles['checkout-card-selection__add-more']}
        onClick={() => setCreateModal(true)}
      >
        <More24 className={styles['checkout-card-selection__add-more-icon']} />
        <Typography variant="button" className={styles['checkout-card-selection__add-more-text']}>
          Adicionar novo
        </Typography>
      </Button>
      <VindiEditOrAddCardModal
        isOpen={createModal}
        sendModal={() => setCreateModal(!createModal)}
        closeModal={() => setCreateModal(!createModal)}
        title="Novo cartão"
        onSelect={(card) => setVindiCard(card)}
        selectedBillingAddress={selectedBillingAddress}
      />
    </div>
  );
};

export default VindiCardSelection;
