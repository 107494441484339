import { Customer, DocumentType } from '~/operations';
import Yup from './yup';

export const genderOptions = [
  { label: 'Masculino', value: 1 },
  { label: 'Feminino', value: 2 },
  { label: 'Prefiro não responder', value: 4 },
];

/**
 * Return labels for magento's gender ids
 * @param gender Magento's gender ID
 */
export const getGender = (gender: number | null | undefined) => {
  return genderOptions.find((g) => g.value === gender) || genderOptions[2];
};

/**
 * Checks if a given customer is CPF
 */
export const isCPF = (customer?: Partial<Customer> | null) => customer?.documentType === DocumentType.Cpf;

/**
 * Validation rules for customer record
 */
export const validation = {
  email: Yup.string().email('Email inválido').required('Campo é obrigatório'),
  password: Yup.string().required('Campo é obrigatório'),
  validPassword: Yup.string()
    .required('Campo é obrigatório')
    // .hasMoreOrEqualThanEightCaracters('No mínimo 8 caracteres')
    // .hasLowerCase('Ao menos uma letra minúscula')
    // .hasUpperCase('Ao menos uma letra maiúscula')
    // .hasNumber('Um número')
    // .hasSpecialCaracters('Um caracter especial (@, _, #, etc)'),
    .hasMoreOrEqualThanSixCaracters('No mínimo 6 caracteres'),
  name: Yup.string().required('Campo é obrigatório'),
  lastName: Yup.string().required('Campo é obrigatório'),
  gender: Yup.object().oneSelected('Campo é obrigatório'),
  dateOfBirth: Yup.string().required('Campo é obrigatório'),
  cpf: Yup.string().cpf('CPF inválido').required('Campo é obrigatório'),
  phone: Yup.string().phoneNumber('Número inválido').required('Campo é obrigatório'),
  anotherPhone: Yup.string().phoneNumber('Número inválido'),
  addressAlias: Yup.string().required('Campo é obrigatório'),
  addressCEP: Yup.string().postalCode('Formato do CEP é inválido').required('Campo é obrigatório'),
  addressStreet: Yup.string().required('Campo é obrigatório').max(54, 'Valor deve ter 54 caracteres ou menos'),
  addressNumber: Yup.string().required('Campo é obrigatório').max(5, 'Valor deve ter 5 caracteres ou menos'),
  addressComplement: Yup.string().max(14, 'Valor deve ter 14 caracteres ou menos'),
  addressNeighborhood: Yup.string().required('Campo é obrigatório').max(45, 'Valor deve ter 45 caracteres ou menos'),
  addressCity: Yup.string().required('Campo é obrigatório').max(50, 'Valor deve ter 50 caracteres ou menos'),
  addressState: Yup.object().oneSelected('Campo é obrigatório'),
  deliveryDetails: Yup.string(),
  agreement: Yup.boolean().oneOf([true], 'Você precisa aceitar os termos e condições da sua assinatura.'),
  companyName: Yup.string().required('Campo é obrigatório'),
  tradingName: Yup.string().required('Campo é obrigatório'),
  cnpj: Yup.string().cnpj('CNPJ inválido').required('Campo é obrigatório'),
  municipalRegistration: Yup.string(),
  stateRegistration: Yup.string().required('Campo é obrigatório'),
  nameOfTheRepresentative: Yup.string().required('Campo é obrigatório'),

  getSchema(props = {}) {
    const schema = {};
    Object.entries(props).forEach(([key, value]) => {
      if (this === undefined || this[key] === undefined) return;
      if (typeof value === 'string') schema[value] = this[key];
      else schema[key] = this[key];
    });

    return this.shape(schema);
  },

  shape(schema) {
    return Yup.object().shape(schema);
  },
};

export * from './format/document';
export * from './format/phone';
export * from './format/postal-code';
