import { DataContainer, DropdownOptions, Radio, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useState } from 'react';
import { VindiDeleteCardModal } from '~/components/forms/VindiDeleteCardModal';
import { CreditCardTokenData } from '~/context/checkout.types';
import { getPublicPath } from '~/utils/getPublicPath';
import styles from './VindiPaymentCard.module.scss';

export interface VindiPaymentCard {
  token: CreditCardTokenData;
  allowDelete?: boolean;
  onSelect?: (token: CreditCardTokenData) => void;
  selected?: boolean;
  className?: string;
  compact?: boolean;
}

export const VindiPaymentCard = ({
  className,
  token,
  allowDelete = false,
  onSelect,
  selected = false,
  compact = false,
}: VindiPaymentCard) => {
  const [deleteModal, showDeleteModal] = useState(false);

  const options: Array<DropdownOptions> = [];
  if (allowDelete)
    options.push({ label: 'Excluir', onClick: () => showDeleteModal(true), className: 'color-red-ziyou' });

  return (
    <DataContainer
      options={options}
      className={clsx(
        className,
        styles.paymentCard,
        onSelect && 'cursor-pointer',
        selected && styles.selected,
        compact && styles.compact
      )}
      onClick={() => onSelect && onSelect(token)}
    >
      <div className={styles.paymentInfoContainer}>
        {onSelect && <Radio name="selectedCard" checked={selected} labelClassName="w-auto m-0" />}
        <div className={styles.card}>
          <img src={getPublicPath(`/cc-bandeiras/${token.issuer}.png`)} />
        </div>
        <Typography variant="h4" weight="bold">
          **** **** **** {token.lastFour}
        </Typography>
      </div>
      <VindiDeleteCardModal
        id={token.id}
        isOpen={deleteModal}
        closeModal={() => showDeleteModal(false)}
        title="Excluir cartão"
      />
    </DataContainer>
  );
};

export default VindiPaymentCard;
