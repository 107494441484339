import { Button, ButtonVariant, Play24, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { Cart } from '~/operations';
import { formatCurrency } from '~/utils/format';
import { getSubscriptionPlanValue } from '~/utils/product';
import { useCartContext } from '~/utils/useCart';
import styles from './OrderItems.module.scss';

export interface OrderItemsProps {
  cart?: Partial<Cart>;
  className?: string;
  confirmation?: boolean;
}

export const OrderItems = ({ cart, className, confirmation = false }: OrderItemsProps) => {
  const products = cart?.items || [];
  const { actions: cartActions } = useCartContext();
  const hasNonVirtualProduct = cartActions.hasNonVirtualProduct();
  return (
    <div className={clsx(styles.items, className)}>
      <div className="d-flex justify-content-between mb-2">
        <Typography variant="caption-2" className="m-0 color-dark">
          {!hasNonVirtualProduct ? 'Itens inclusos' : confirmation ? 'Equipamentos' : 'Carrinho'}
        </Typography>
        {hasNonVirtualProduct && !confirmation && (
          <Link href="/carrinho">
            <Button variant={ButtonVariant.LINK} className="d-none d-lg-block">
              Editar
            </Button>
          </Link>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <Link href="/club">
          <a>
            <div className={styles.acesso}>
              <Play24 />
              <Typography>Acesso as aulas</Typography>
            </div>
          </a>
        </Link>
      </div>
      <ul>
        {products.map(
          (item: any) =>
            item && (
              <li key={item.id}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    {/* giving a size so that alignment would be proper when the value is 1x 2x 3x 4x... */}
                    <Typography className={styles.size} variant="h5">{`${item.quantity}x`}</Typography>
                    <Typography className="pl-1" variant="h6">
                      {item.product.name}
                    </Typography>
                  </div>
                  <div className={styles.price}>
                    <Typography variant="h6">{`${formatCurrency(item.prices?.price?.value || 0)}/m`}</Typography>
                    {item?.customizable_options?.length > 0 && (
                      <Typography variant="body-text-1" className="mt-1">
                        {getSubscriptionPlanValue(item?.customizable_options)}
                      </Typography>
                    )}
                  </div>
                </div>
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default OrderItems;
