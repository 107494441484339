import { Typography } from '@objectedge/ziyou-storefront-ds';
import { MinimumRequirement } from '~/utils/product';
import styles from './ProductDescriptionCard.module.scss';

type ProductDescriptionCardProps = {
  name: string;
  image: string;
  index: number;
  info: Array<MinimumRequirement>;
};

export const ProductDescriptionCard = ({ name, image, index, info }: ProductDescriptionCardProps) => (
  <div className={styles['product-description-card']}>
    <Typography variant="h5" className={styles['product-description-card__title']}>
      {`${index + 1 >= 10 ? index + 1 : `0${index + 1}`}.${name}`}
    </Typography>
    <div className={styles.imageContainer}>
      <img src={image} alt={name} className={styles['product-description-card__image']} />
    </div>
    <ul className={styles['product-description-card__list']}>
      {info.map(({ label, value }) => (
        <li key={label} className={styles['product-description-card__list-item']}>
          <Typography variant="h5" weight="bold" className={styles['product-description-card__list-item-key']}>
            {label}
          </Typography>
          <Typography variant="h6" className={styles['product-description-card__list-item-value']}>
            {value}
          </Typography>
        </li>
      ))}
    </ul>
  </div>
);

export default ProductDescriptionCard;
