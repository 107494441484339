import { LocalDate } from '@js-joda/core';
import { Button, ButtonVariant, CheckWhite24, DateSelector, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { useGetOpenSlotsLazyQuery } from '~/operations';
import styles from './CheckoutSchedulingSelection.module.scss';

// TODO: Did this because on the beggining we won't have shifts selection enabled
const HIDE_SHIFT = true;
const MORNING = '08:00:00';
const AFTERNOON = '13:00:00';

// TODO: Make address dynamic based on the address customer selected
const DEFAULT_ADDRESS = {
  city: 'São Paulo',
  country: 'BR',
  state: 'SP',
  postalCode: '01001000',
};

const buildStartingDates = () => {
  const start = LocalDate.now();
  return [...new Array(7).keys()].map((i) => ({
    date: start.plusDays(i),
    selected: false,
    disabled: true,
    slots: [],
  }));
};

export interface CheckoutSchedulingSelectionProps {
  selectedDate: string;
  selectedSlot: string;
  setValue: any;
  checkoutRef: MutableRefObject<any>;
}

export const CheckoutSchedulingSelection = ({
  selectedSlot,
  selectedDate,
  setValue,
  checkoutRef,
}: CheckoutSchedulingSelectionProps) => {
  const [getOpenSlots, { data: openSlotsData }] = useGetOpenSlotsLazyQuery({
    variables: { start: LocalDate.now().plusDays(1), end: LocalDate.now().plusDays(25), address: DEFAULT_ADDRESS },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    !openSlotsData && getOpenSlots();
  }, []);

  const setDate = useCallback((v: LocalDate) => {
    setValue('selectedDate', v.toString());
    setValue('selectedSlot', HIDE_SHIFT ? MORNING : undefined);
  }, []);

  const dates = !openSlotsData
    ? buildStartingDates()
    : (openSlotsData?.openSlots?.daysWithOpenSlots || []).map((slot) => ({
        date: LocalDate.parse(slot?.day),
        selected: selectedDate === slot?.day,
        disabled: !slot?.slots?.length,
        slots: slot?.slots || [],
      }));

  openSlotsData &&
    dates?.some(({ disabled }, index) => {
      if (dates[1]?.disabled) {
        dates?.shift();
      } else {
        return true;
      }
    });

  const isSlotAvailable = (selectedDate, slot) => {
    const day = dates?.find(({ date }) => date.toString() === selectedDate);
    const availableSlot = day?.slots.find((s) => s?.start === slot);
    return !!availableSlot;
  };

  return (
    <div className={styles.schedulingSelection} ref={checkoutRef}>
      <Typography variant="h4">Agendamento</Typography>
      <div className={clsx(!openSlotsData && styles.loading)}>
        <div className={styles.loadingOverlay}></div>
        <Typography variant="caption-2">Dia</Typography>
        <DateSelector dates={dates} onSelect={setDate} className="mb-3" settings={{ slidesToScroll: 7 }} />

        {!HIDE_SHIFT && (
          <>
            <Typography variant="caption-2">Turno</Typography>
            <div className={styles.changeScheduling}>
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => setValue('selectedSlot', MORNING)}
                className={clsx(selectedSlot === MORNING && styles.active)}
                disabled={!selectedDate || !isSlotAvailable(selectedDate, MORNING)}
              >
                <CheckWhite24 />
                <Typography variant="caption-2">
                  Manhã{!isSlotAvailable(selectedDate, MORNING) ? ' (indisponível)' : ''}
                </Typography>
              </Button>
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => setValue('selectedSlot', AFTERNOON)}
                className={clsx(selectedSlot === AFTERNOON && styles.active)}
                disabled={!selectedDate || !isSlotAvailable(selectedDate, AFTERNOON)}
              >
                <CheckWhite24 />
                <Typography variant="caption-2">
                  Tarde{!isSlotAvailable(selectedDate, AFTERNOON) ? ' (indisponível)' : ''}
                </Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutSchedulingSelection;
