import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Loader, Typography } from '@objectedge/ziyou-storefront-ds';
import { NextRouter, useRouter } from 'next/router';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorText } from '~/components/forms';
import { IsValidResetPasswordTokenDocument, ResetPasswordDocument } from '~/operations';
import { useAlertsContext } from '~/utils/useAlerts';
import Yup from '~/utils/yup';
import styles from './ResetPasswordForm.module.scss';

type ResetPasswordFormData = {
  password: string;
};

const schema = Yup.object().shape({
  password: Yup.string().required('Campo é obrigatório'),
});

const getSearchTerm = ({ query }: NextRouter): string => {
  const term = query.token;
  if (typeof term === 'string') return term;
  if (Array.isArray(term)) return term[0];
  return '';
};

export const ResetPasswordForm = () => {
  const Router = useRouter();
  const token = getSearchTerm(Router);
  const { loading, data } = useQuery<{ isValidResetPasswordToken: Boolean }>(IsValidResetPasswordTokenDocument, {
    variables: {
      token: token,
    },
  });
  const [resetPassword] = useMutation(ResetPasswordDocument);
  const { actions: alertsActions } = useAlertsContext();
  const { watch, register, handleSubmit, errors } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
  });
  const password = watch('password') || '';
  const hasMinimumLength = password.length >= 6;
  const isPasswordFieldValid = hasMinimumLength;

  const onSubmit = useCallback(async (formValues: ResetPasswordFormData) => {
    try {
      if (isPasswordFieldValid) throw 'invalidPassword';

      const { data } = await resetPassword({
        variables: {
          token: token,
          password: formValues.password,
          confirmPassword: formValues.password,
          tenant: 'store',
        },
      });

      if (data?.resetPassword?.success) {
        Router.push('login');
        alertsActions.addSuccessAlert('Senha redefinida com sucesso');
      } else {
        throw 'invalidPassword';
      }
    } catch (err) {
      alertsActions.addDangerAlert('Preencha o campo de senha corretamente');
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles['main']}>
          {data?.isValidResetPasswordToken ? (
            <div>
              <Typography className={styles['title']} variant="h1" weight="bold">
                Redefinição de senha
              </Typography>
              <Input
                type="password"
                label="Digite a sua nova senha"
                canToggleVisibility
                className={styles['input'] + 'fs-exclude'}
                name="password"
                ref={register}
                status={errors.password ? 'danger' : 'default'}
                helperText={errors.password?.message ?? ''}
                error={!!errors.password?.message}
              />
              {password ? (
                <div className={styles['about-your-account__password-checks']}>
                  <ErrorText hasTheError={!hasMinimumLength}>No mínimo 6 caracteres</ErrorText>
                </div>
              ) : (
                <div className={styles['about-your-account__password-checks']}>
                  <ErrorText hasNotValidated>No mínimo 6 caracteres</ErrorText>
                </div>
              )}
              <div className={styles['buttons-container']}>
                <Button className={styles['button']} onClick={handleSubmit(onSubmit)}>
                  Redefinir
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles['token-expirated']}>
              <Typography className={styles['title']} variant="h1" weight="bold">
                Token expirado
              </Typography>
              <Button className={styles['button-expirated']} onClick={() => Router.push('login/reset')}>
                Enviar outro email
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
