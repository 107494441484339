import { CreditCardType } from '~/operations';

const map = {
  visa: CreditCardType.Visa,
  mastercard: CreditCardType.Master,
  amex: CreditCardType.Amex,
  elo: CreditCardType.Elo,
  aura: CreditCardType.Aura,
  jcb: CreditCardType.Jcb,
  dinersclub: CreditCardType.Diners,
  discover: CreditCardType.Discover,
  hipercard: CreditCardType.Hipercard,
  hiper: CreditCardType.Hiper,
};

export const mapCardType = (issuer) => map[issuer];

export default mapCardType;
