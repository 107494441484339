import { Typography } from '@objectedge/ziyou-storefront-ds';
import { FC } from 'react';
import styles from './UserInfoSection.module.scss';

export interface UserInfoSectionProps {
  icon: React.ReactNode;
  title: string;
}

export const UserInfoSection: FC<UserInfoSectionProps> = ({ children, icon, title }) => (
  <section className={styles['user-info']}>
    <Typography variant="caption-2" className={styles['user-info__title']}>
      {title}
    </Typography>
    <div className={styles['user-info__content']}>
      <div className={styles['user-info__content-icon']}>{icon}</div>
      <div className={styles['user-info__content-wrapper']}>{children}</div>
    </div>
  </section>
);

export default UserInfoSection;
