import {
  Col,
  getCardCVCLength,
  getCardCVCMask,
  getCardMask,
  Help24,
  Input,
  Row,
  TextfieldCommonMasks,
  Tooltip,
} from '@objectedge/ziyou-storefront-ds';
import { useEffect, useMemo, useState } from 'react';
import Cards from 'react-credit-cards';
import styles from './CreditCardForm.module.scss';

export interface CreditCardFormProps {
  errors: any;
  register: any;
  watch: any;
  setValue: Function;
  setVindiCard: (card: any | undefined) => void;
}

type FocusedField = 'number' | 'name' | 'cvc' | 'expiry' | undefined;

export const CreditCardForm = ({ watch, register, errors, setValue, setVindiCard }: CreditCardFormProps) => {
  const [focusedField, setFocusedField] = useState<FocusedField>(undefined);

  const creditCardCVC = watch('creditCardCVC');
  const creditCardNumber = watch('creditCardNumber');
  const creditCardUsername = watch('creditCardUsername');
  const creditCardExpiration = watch('creditCardExpiration');
  const creditCardIssuer = watch('creditCardIssuer');

  useEffect(() => {
    if (focusedField) {
      setVindiCard(undefined);
    }
  }, [focusedField]);

  const formatedExpiration = useMemo(() => {
    const number = creditCardExpiration.replaceAll(/[^\d]/g, '');
    return number.substr(0, 2) + '/' + number.substr(4, 2);
  }, [creditCardExpiration]);

  return (
    <>
      <Row>
        <Col md={6} lg={6} xl={7}>
          <Input
            label="Número"
            name="creditCardNumber"
            placeholder="0000 0000 0000 0000"
            className="fs-exclude"
            mask={getCardMask(creditCardIssuer)}
            register={register}
            setValue={setValue}
            isCreditCard
            onFocus={() => setFocusedField('number')}
            onBlur={() => setFocusedField(undefined)}
            status={errors.creditCardNumber ? 'danger' : 'default'}
            helperText={errors.creditCardNumber?.message ?? ''}
          />
          <Input
            label="Nome"
            name="creditCardUsername"
            placeholder="Nome e sobrenome"
            ref={register}
            onFocus={() => setFocusedField('name')}
            onBlur={() => setFocusedField(undefined)}
            status={errors.creditCardUsername ? 'danger' : 'default'}
            helperText={errors.creditCardUsername?.message ?? ''}
          />
        </Col>
        <Col md={6} lg={6} xl={5} className="d-none d-md-block d-lg-block">
          <div className={styles['checkout-card-selection__card-component'] + ' fs-exclude'}>
            <Cards
              cvc={creditCardCVC.substring(0, getCardCVCLength(creditCardIssuer))}
              expiry={formatedExpiration}
              name={creditCardUsername}
              number={creditCardNumber}
              focused={focusedField}
              placeholders={{ name: 'Seu nome' }}
              callback={({ issuer }) => setValue('creditCardIssuer', issuer)}
            />
            <input type="hidden" name="creditCardIssuer" ref={register} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} lg={3} xl={3}>
          <Input
            label="Data de validade"
            name="creditCardExpiration"
            mask={TextfieldCommonMasks.cardExpireDate}
            placeholder="MM/AAAA"
            register={register}
            setValue={setValue}
            onFocus={() => setFocusedField('expiry')}
            onBlur={() => setFocusedField(undefined)}
            status={errors.creditCardExpiration ? 'danger' : 'default'}
            helperText={errors.creditCardExpiration?.message ?? ''}
          />
        </Col>
        <Col xs={6} lg={3} xl={4}>
          <div className={styles['checkout-card-selection__cvc-wrapper']}>
            <Input
              label="CVC"
              name="creditCardCVC"
              className="fs-exclude"
              placeholder="000"
              mask={getCardCVCMask(creditCardIssuer)}
              register={register}
              setValue={setValue}
              onFocus={() => setFocusedField('cvc')}
              onBlur={() => setFocusedField(undefined)}
              status={errors.creditCardCVC ? 'danger' : 'default'}
              helperText={errors.creditCardCVC?.message ?? ''}
            />
            <Tooltip
              id="help-cvc"
              text="CVV é um código de segurança de 3 dígitos, impresso no verso de cartões de crédito. Importante: Em cartões American Express você encontra o CVV com 4 dígitos e na frente."
            >
              <Help24 id="help-cvc" className={styles['checkout-card-selection__cvc-icon']} />
            </Tooltip>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreditCardForm;
