import { Typography } from '@objectedge/ziyou-storefront-ds';
import { Dispatch, SetStateAction, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { checkEmail } from '~/utils/checkEmail';

export interface EmailSuggestionProps {
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  setFormValue?: UseFormMethods<Record<string, string>>['setValue'];
  fieldName?: string;
}

export const EmailSuggestion = ({ value, setValue, setFormValue, fieldName }: EmailSuggestionProps) => {
  const [emailSuggestion, setEmailSuggestion] = useState<string>();
  const hasEmailSpecified = /\w([@])\w+([.])\w{2,}/g.test(value);
  if (!hasEmailSpecified) {
    return <div>&nbsp;</div>;
  }
  checkEmail(value).then((res) => {
    setEmailSuggestion(res.suggestion);
  });
  if (emailSuggestion === '' || !emailSuggestion) {
    return <div>&nbsp;</div>;
  }
  return (
    <Typography variant="caption-2">
      Você quis dizer:{' '}
      <a
        className="link cursor-pointer"
        onClick={() => {
          if (setValue) {
            setValue(emailSuggestion);
          }
          if (setFormValue && fieldName) {
            setFormValue(fieldName, emailSuggestion);
          }
          setEmailSuggestion('');
        }}
      >
        {emailSuggestion}?
      </a>
    </Typography>
  );
};

export default EmailSuggestion;
