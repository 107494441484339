import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonSize, Form, Input, Typography } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useGenerateCustomerTokenMutation } from '~/operations';
import { useAlertsContext } from '~/utils/useAlerts';
import { useCartContext } from '~/utils/useCart';
import { useCookiesContext } from '~/utils/useCookies';
import Yup from '~/utils/yup';
import styles from './LoginForm.module.scss';

type LoginFormData = {
  email: string;
  password: string;
};

const schema = Yup.object().shape({
  email: Yup.string().trim().email('Email inválido').required('Campo é obrigatório'),
  password: Yup.string().required('Campo é obrigatório'),
});

export interface LoginFormProps {
  redirectTo?: string;
}

export const LoginForm = ({ redirectTo = '/minha-conta' }: LoginFormProps) => {
  const Router = useRouter();
  const { actions: cookieActions, state: cookieValues } = useCookiesContext();
  const { actions: cartActions, state: cartState } = useCartContext();
  const { actions: alertsActions } = useAlertsContext();
  const { userEmail } = cookieValues;

  const isInSoftLogin = cartActions.isInSoftLoginState();

  const [generateCustomerToken, { loading: loggingIn }] = useGenerateCustomerTokenMutation({
    onCompleted: (data: any) => {
      cookieActions.updateUserTokenCookie(data?.generateCustomerToken?.token);
      const isVirtual = cartState?.products?.some((element) => element?.productType === 'virtual');
      if (isVirtual) {
        cartActions.resetCart();
      } else {
        cartActions.mergeCart(data?.generateCustomerToken?.token);
      }
    },
  });

  const { register, handleSubmit, errors } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: isInSoftLogin ? userEmail : '',
    },
  });

  const onSubmit = useCallback(async (formValues: LoginFormData) => {
    try {
      await generateCustomerToken({
        variables: {
          email: formValues.email,
          password: formValues.password,
        },
      });

      cookieActions.updateUserEmail(formValues.email);
      Router.push(redirectTo);
    } catch (err: any) {
      let errorMessage = 'Usuário e senha incorretos ou não cadastrados.';
      if (err?.message?.includes('tente novamente depois')) {
        errorMessage = `${errorMessage} Tente redefinir a sua senha, clicando no link esqueci minha senha.`;
      }

      alertsActions.addDangerAlert(errorMessage, {
        id: 'invalid-login',
      });
    }
  }, []);

  return (
    <section className={styles['login-form']}>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles['login-form__content']}>
        <Typography variant="h1" className={styles['login-form__title']}>
          Já sou um assinante
        </Typography>
        <Input
          id="login-email-input"
          label="Email"
          name="email"
          ref={register}
          status={errors.email ? 'danger' : 'default'}
          helperText={errors.email?.message ?? ''}
          error={!!errors.email?.message}
        />
        <Input
          id="login-password-input"
          type="password"
          label="Senha"
          name="password"
          className="fs-exclude"
          ref={register}
          canToggleVisibility
          status={errors.password ? 'danger' : 'default'}
          helperText={errors.password?.message ?? ''}
          error={!!errors.password?.message}
        />
        <Link href="/login/reset">
          <Typography variant="link" id="login-forgot-password-link" className={styles['login-form__lost-password']}>
            Esqueci minha senha
          </Typography>
        </Link>
        <Button
          size={ButtonSize.LG}
          id="login-button"
          className={styles['login-form__login-button']}
          disabled={loggingIn}
        >
          Acessar
        </Button>
      </Form>
    </section>
  );
};

export default LoginForm;
