import { Button, Checkbox, Typography } from '@objectedge/ziyou-storefront-ds';
import { OrderItems } from '~/components/checkout';
import OrderTotals from '~/components/checkout/OrderTotals';
import { CouponInput } from '~/components/forms';
import styles from './CheckoutSidebar.module.scss';

export interface CheckoutSidebarProps {
  onSubmit: () => void;
  cart?: any;
  setAgreement: Function;
  agreementError: boolean;
}

export const CheckoutSidebar = ({ onSubmit, cart = {}, setAgreement, agreementError }: CheckoutSidebarProps) => (
  <aside className={styles.checkoutSidebar}>
    <Typography variant="h4" className="px-lg-0 pb-lg-3 pt-lg-0">
      Resumo da Assinatura
    </Typography>
    <OrderItems cart={cart} />
    <CouponInput cart={cart} />
    <OrderTotals cart={cart} className="mb-1" />

    <div className={styles.actions}>
      <Button id="assinar-btn" className="w-100" onClick={onSubmit}>
        Assinar
      </Button>
    </div>
    <div className={styles.label}>
      <Checkbox
        name="agreement"
        id="agreement"
        defaultChecked={true}
        status={agreementError ? 'error' : ''}
        onChange={(v) => setAgreement(v.target.checked)}
        label={
          <Typography variant="body-text-1">
            Ao clicar no botão "Assinar", você concorda com os{' '}
            <a href="/termos-e-condicoes" target="_blank">
              Termos e Condições
            </a>{' '}
            e{' '}
            <a href="/politica-de-privacidade" target="_blank">
              Política de Privacidade
            </a>
            .
          </Typography>
        }
      />
    </div>
  </aside>
);

export default CheckoutSidebar;
