import { Button, ButtonVariant, More24, Typography } from '@objectedge/ziyou-storefront-ds';
import { MutableRefObject, useState } from 'react';
import { AddressCard } from '../AddressCard';
import { CheckoutEditOrAddAddressModal } from '../CheckoutEditOrAddAddressModal';
import styles from './CheckoutAddressSelection.module.scss';

export interface CheckoutAddressSelectionProps {
  addressType: 'delivery' | 'billing';
  selectedAddress: any;
  addresses: any[];
  customerInfo: {
    firstName: string;
    lastName: string;
  };
  selectAddress: Function;
  addressRef: MutableRefObject<any>;
}

export const CheckoutAddressSelection = ({
  addressType,
  selectedAddress = {},
  addresses,
  customerInfo,
  selectAddress,
  addressRef,
}: CheckoutAddressSelectionProps) => {
  const [createModal, setCreateModal] = useState(false);
  return (
    <div ref={addressRef}>
      <Typography variant="h4" className={styles['checkout-address-selection__title']}>
        {`Endereço de ${addressType === 'delivery' ? 'entrega' : 'cobrança'}`}
      </Typography>
      <ul className={styles['checkout-address-selection__address-options']}>
        {addresses.map((addressOption) => (
          <AddressCard
            key={addressOption.id}
            addressOption={addressOption}
            addressType={addressType}
            customerInfo={customerInfo}
            selected={selectedAddress?.id === addressOption.id}
            onSelect={selectAddress}
            allowDelete={addresses.length > 1}
          />
        ))}
      </ul>
      <Button
        variant={ButtonVariant.SECONDARY}
        className={styles['checkout-address-selection__add-more']}
        onClick={() => setCreateModal(true)}
      >
        <More24 className={styles['checkout-address-selection__add-more-icon']} />
        <Typography variant="button" className={styles['checkout-address-selection__add-more-text']}>
          Adicionar novo
        </Typography>
      </Button>
      <CheckoutEditOrAddAddressModal
        isOpen={createModal}
        closeModal={() => setCreateModal(!createModal)}
        actionType="create"
        title="Novo endereço"
        onSelect={selectAddress}
        customerInfo={customerInfo}
        addressType={addressType}
      />
    </div>
  );
};

export default CheckoutAddressSelection;
